import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Modal,
  Form,
  Switch,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.scss";
import useApiRequest from "../../../helper/useApiHook";
// const { Option } = Select;
const CreateAttractionType = (props) => {

  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (props.showModal) {
      setModal(true);
      if (props.attractionType) {
        form.setFieldsValue({
          type_name: props.attractionType.type_name,
          icon: props.attractionType.icon,
          can_user_review: props.attractionType.can_user_review,
        });
      } else {
        form.setFieldsValue({
          can_user_review: true,
        });
      }
    }
  }, [props.showModal]);

  const {
    data: attractionTypeData,
    error: attractionTypeError,
    loading: attractionTypeLoading,
    makeRequest: createAttractionData,
  } = useApiRequest();

  const onChange = (val) => {
    console.log(val);
    form.setFieldsValue({ can_user_review: val });
  };

  const toggleModal = (params) => {
    if (modal) {
      setModal(false);
      props.hideModal(params);
    }
  };

  const onFinish = (values) => {
    let body = {
      type_name: values.type_name,
      icon: values.icon,
      can_user_review: values.can_user_review ? values.can_user_review : false,
    };

    if (!props.attractionType) {
      createAttractionData("/attractions-types", "POST", body);
    } else {
      createAttractionData(
        `/attractions-types/${props.attractionType.key}`,
        "PUT",
        { ...body, id: props.attractionType.key }
      );
    }
  };

  useEffect(() => {
    if (attractionTypeError) {
      message.error("Failed to create/update attraction type");
    }
    if (attractionTypeData) {
      message.success(
        `${
          !props.attractionType ? "Created" : "Updated"
        } attraction type successfully`
      );
      toggleModal(false);
    }
  }, [attractionTypeError, attractionTypeData]);

  return (
    <Modal visible={modal} onCancel={toggleModal} footer={false}>
      <div
        style={{
          display: "flex",
          height: "60px",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div className="home-team">
          <span className="team-name">
            {!props.attractionType ? "Create" : "Update"} Attraction Type
          </span>
        </div>
      </div>
      <Form form={form} onFinish={onFinish}>
     
        <div>
          <label>Title:</label>
          <Form.Item name="type_name">
            <Input />
          </Form.Item>
        </div>

        <div>
          {props.attractionType && (
            <img
              src={props.attractionType.icon}
              style={{ height: "25px", width: "25px", marginRight: "5px" }}
            />
          )}
          <label> Icon:</label>
          <Form.Item name="icon">
            <Input />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="can_user_review" valuePropName="checked">
            <label>Can user review:</label>
            <Switch
              style={{ marginLeft: "1em" }}
              onChange={onChange}
              defaultChecked={true}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "1em" }}
            >
              Submit
              {attractionTypeLoading ? <LoadingOutlined /> : null}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export default CreateAttractionType;
