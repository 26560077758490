import React, {} from 'react';
import {
    Table,
    Rate,
    Switch,
    Result,
    Tooltip,
    Button,
    Modal,
    Spin,
    AutoComplete,
    Form,
    Input,
    Tag
  } from "antd";
  import { Toast, ToastHeader, ToastBody } from "reactstrap";
  import { Context } from "../../Provider";
  import "./PubsReviews.scss";
  import { LoadingOutlined } from "@ant-design/icons";
  import axios from "axios";
  import StarRatings from "react-star-ratings";
  import { Label } from "reactstrap";
  import { isEmpty } from "lodash";
  import AlertNotification from "../AlertNotification";
  import SupporterForm from "../Forms/SupporterForm";
  import StadiumForm from "../Forms/StadiumForm";
  import API from '../../helper/api';

  const { TextArea } = Input;



export const PubsModal = (props) =>{
    const {        
        toggleModal,
        connectModal,        
        searchStadiumValue,
        setSearchStadiumValue,
        stadiumOptions,
        setStadiumOptions,
        stadium,
        setStadium,        
        setToastErrors,
        supporter,
        setSupporter,
        searchSupporterValue,
        setSearchSupporterValue,
        supporterOptions,
        setSupporterOptions,
        isLoading,
        setIsLoading,        
        setCurrentStadium,
        currentStadium
      } = React.useContext(Context);
    
    const [showForm, setShowForm] = React.useState({ form: "", show: false });
    const [info, setReviewInfo] = React.useState({about:'', change: false});    
    const [selectedReview, setSelectedReview] = React.useState({});

    React.useEffect(()=>{
            currentStadium.stadium_id&&getStadiumDetail(currentStadium.stadium_id);
            currentStadium.supporter_id&&getSupporterDetail(currentStadium.supporter_id);
            currentStadium.about&&setReviewInfo({ change:false ,about:currentStadium.about})
    },[])

    //EFFECT 
    const onChangeApproval = (val) => {        

          API.put("supporter-reviews-approval",{review_id: currentStadium.connect.reviewId,approved:val})
          .then((res) => {
            if(res.data.success ){                
              
              const updateStadium = props.supportersData.map((review) => {

                if(review.connect.reviewId === res.data.data._id){
                  review.approved = res.data.data.approved; 
                }

                return review
              });
              
              props.setSupportersData(updateStadium);
              
            }
          })
          .catch((err) => {
            AlertNotification(err.message);
            
          });
      };

      const getStadiumDetail = (id) => {
        axios
          .get(`https://www.footballaroundme.com/api/get-stadium/${id}`)
          .then((res) => {
            setIsLoading(false);
            console.log(res.data.data.stadium,'res.data.data.stadium')
            setStadium(res.data.data.stadium);
          })
          .catch((err) => setToastErrors(err.message));
      };
    
      const getSupporterDetail = async (id) => {
        axios
          .get(`https://www.footballaroundme.com/api/get-supporter/${id}`)
          .then((res) => {
            setIsLoading(false);
            console.log(res.data.data.supporters,'res.data.data.supporters')
            setSupporter(res.data.data.supporters);
          })
          .catch((err) => setToastErrors(err.message));
      };
      const createForm = (value) => {
        value === "supporter"
          ? setSearchSupporterValue("")
          : setSearchStadiumValue("");         
        setShowForm({ form: value, show: true });
      };
      const onSupporter = (value) => {
        setIsLoading(true);
        axios
          .post("https://www.footballaroundme.com/api/create-supporter", {
            ...value,
            stadium: stadium._id,
          })
          .then(async (res) => {
            console.log({ res });
    
            setIsLoading(false);
            const { success, data, error } = res.data;
            console.log({ e: res.data });
            if (success) {
              setShowForm({ form: "", show: false });
              setSearchSupporterValue(data.name);
              getSupporterDetail(data._id);
              return true;
            }
            AlertNotification(error);
          })
          .catch((err) => {
            setIsLoading(false);
            AlertNotification(err.message);
          });
      };
      const onStadium = (value) => {
        setIsLoading(true);
        axios
          .post("https://www.footballaroundme.com/api/create-stadium", {
            ...value,
          })
          .then(async (res) => {
            console.log({ res });
    
            setIsLoading(false);
            const { success, data, error } = res.data;            
            if (success) {
              setShowForm({ form: "", show: false });
              setSearchStadiumValue(data.name);
              getStadiumDetail(data._id);
              return true;
            }
            AlertNotification(error);
          })
          .catch((err) => {
            setIsLoading(false);
            AlertNotification(err.message);
          });
      };
    
    
    return (
        <Modal
        title={
          !showForm.show
            ? "Connect Stadium and Pub"
            : `Create New ${showForm.form==='pub'?'Pub': showForm.form}`
        }
        visible={connectModal}
        onCancel={() => {
          toggleModal();
          setSearchStadiumValue("");
          setSearchSupporterValue("");
          setStadium(null);
          setSupporter(null);
          setStadium({});
          setCurrentStadium({});
          setShowForm({ form: "", show: false });
          setSelectedReview({});
        }}
        footer={null}
      >
        <Spin spinning={isLoading}>
          {!showForm.show ? (
            <div>
              <div  className='user-origin'><h4>User's Original Review</h4></div>
              <div>
                <span className="label">Stadium Name : </span>
                <span className="label">{currentStadium.connect.stadiumName}</span>
              </div>
              <div>
                <span className="label">Team Name : </span>
                <span className="label">{currentStadium.connect.teamName}</span>
              </div>
              <div>
                <span className="label">Pub Name : </span>
                <span className="label">{currentStadium.supporter}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label style={{ fontWeight: "bold", marginTop: "7px" }}>
                  Search Stadium
                </Label>
                <AutoComplete
                  placeholder="Search stadium.."
                  value={searchStadiumValue}
                  onChange={(val) => {
                    setSearchStadiumValue(val);
                  }}
                  options={searchStadiumValue ? stadiumOptions : []}
                  onSearch={(searchText) => {
                    if (searchText.length > 2) {
                      axios
                        .get(
                          `https://www.footballaroundme.com/api/location-search?text=${searchText}`
                        )
                        .then((res) => {
                          const arr = [];
                          res.data.data.stadiums.forEach((item) =>
                            arr.push({ item, value: item.venue })
                          );
                          if (isEmpty(arr)) {
                            arr.push({
                              value: "Create new stadium",
                            });
                          }
                          return setStadiumOptions(arr);
                        })
                        .catch((err) => console.log(err));
                    }
                  }}
                  onSelect={(value, option) =>
                    option.value === "Create new stadium"
                      ? createForm("stadium")
                      : getStadiumDetail(option.item._id)
                  }
                />
                <div>
                  {stadium ? (
                    <div style={{ marginTop: "1em" }}>
                      <h4>Stadium Detail</h4>
                      <div>
                        <span className="label">Venue : </span>

                        {stadium.venue || "Not Available"}
                      </div>
                      <div>
                        <span className="label">Country : </span>

                        {stadium.country || "Not Available"}
                      </div>
                      <div>
                        <span className="label">Website : </span>
                        {stadium.website ? (
                          <a
                            href={stadium.website}
                            target="_blank"
                            style={{ marginLeft: "5px" }}
                          >
                            {stadium.website}
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </div>
                      <div>
                        <span className="label">Location : </span>
                        {stadium.location ? (
                          <a
                            href={
                              "https://www.google.com/maps/place/" +
                              stadium.location.lat +
                              "," +
                              stadium.location.lng
                            }
                            target="_blank"
                            style={{ marginLeft: "5px" }}
                          >
                            Open Location
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <Label style={{ fontWeight: "bold", marginTop: "7px" }}>
                  Search Pub
                </Label>
                <AutoComplete
                  placeholder="Search pub.."
                  value={searchSupporterValue}
                  onChange={(val) => {
                    setSearchSupporterValue(val);
                  }}
                  options={searchSupporterValue ? supporterOptions : []}
                  onSelect={(value, option) => {
                    option.value === "Create new pub"
                      ? createForm("pub")
                      : getSupporterDetail(option.item._id);
                  }}
                  onSearch={(searchText) => {
                    if (searchText.length > 2) {
                      axios
                        .get(
                          `https://www.footballaroundme.com/api/supporter-search?text=${searchText}`
                        )
                        .then((res) => {
                          const arr = [];
                          res.data.data.supporters.forEach((item) =>
                            arr.push({ item, value: item.name })
                          );
                          if (isEmpty(arr)) {
                            arr.push({
                              value: "Create new pub",
                            });
                          }
                          return setSupporterOptions(arr);
                        })
                        .catch((err) => setToastErrors(err.message));
                    }
                  }}
                />
                <div>
                  {supporter ? (
                    <div style={{ marginTop: "1em" }}>
                      <h4>Pub Detail</h4>

                      <div>
                        <span className="label">Name:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                          {supporter.name}
                        </span>
                      </div>
                      <div>
                        <span className="label">Address:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                          {supporter.address}
                        </span>
                      </div>
                      <div>
                        <span className="label">Phone:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                          {supporter.phone}
                        </span>
                      </div>
                      <div>
                        <span className="label">Team:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                          {supporter.team}
                        </span>
                      </div>
                      <div>
                        <span className="label">Location : </span>
                        {supporter.location ? (
                          <a
                            href={
                              "https://www.google.com/maps/place/" +
                              supporter.location.lat +
                              "," +
                              supporter.location.lng
                            }
                            target="_blank"
                            style={{ marginLeft: "5px" }}
                          >
                            Open Location
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div>
                <div style={{ marginTop: "1em" }}>
                      <h4>Edit Detail</h4>

                </div>
                <span className="label">About:</span>
                   <TextArea rows={6} onChange={(e)=>{
                     
                     setReviewInfo({change: true, about : e.target.value})

                   }} value={info.about} style={{ marginLeft: "5px", fontWeight: "500" }}/>                        
                </div>  
              </div>
              <div className={'status-block'}>
                  <Button
                    className="status-block__approve-button"
                    type="primary"                    
                    onClick={(e)=>{
                      onChangeApproval(true)
                    }}
                  >Approve
                  </Button>
                  <Button
                    className="status-block__decline-button"
                    type="primary"                    
                    onClick={(e)=>{
                      onChangeApproval(false)
                    }}
                  >Decline
                  </Button>
                </div> 
              <Button
                className="connect-button"
                type="primary"
                disabled={!stadium || !supporter}
                onClick={() => {
                  setIsLoading(true);
                  var urlencoded = new URLSearchParams();
                  urlencoded.append(
                    "review_id",
                    currentStadium.connect.reviewId ? currentStadium.connect.reviewId.toString() : ""
                  );
                  urlencoded.append("stadium_id", stadium._id.toString());
                  urlencoded.append("supporter_id", supporter._id.toString());
                  if(info.change){
                    urlencoded.append("about", info.about.toString());
                  }


                  var requestOptions = {
                    method: "PUT",
                    body: urlencoded,
                    redirect: "follow",
                  };

                  fetch(
                    "https://www.footballaroundme.com/api/supporter-reviews-linking",
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((result) => {
                      console.log(result);
                      setIsLoading(false);
                      const { success, data, error } = result;
                      if (success) {
                        console.log("connected");                     
                        const updateStadium = props.supportersData.map((review) => {
                            if(review._id === data._id){

                                review.stadium_id = data.stadium_id
                                review.supporter_id = data.supporter_id;
                            }

                            return review
                        });
                        
                        props.setSupportersData(updateStadium);    

                        toggleModal();
                        setSearchSupporterValue("");
                        setSearchStadiumValue("");
                        setStadium(null);
                        setSupporter(null);
                        setSelectedReview({});                        
                        setStadium({});
                        setCurrentStadium({});

                        return true;
                      }
                      AlertNotification(error);
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      setToastErrors(err.message);
                    });
                }}
              >
                Connect Pub
              </Button>
            </div>
          ) : showForm.form === "pub" ? (
            <SupporterForm
              onFinish={onSupporter}
              selectedReview={currentStadium}
              stadium={stadium}
            />
          ) : (
            <StadiumForm onFinish={onStadium} selectedReview={currentStadium} />
          )}
        </Spin>
      </Modal>
    )
}