import React from "react";
import axios from "axios";
import { Spin } from "antd";

export const Context = React.createContext();

export const Provider = (props) => {
  // Search Functionality of leagues
  const [leaguesSearchInputValue, setLeaguesSearchInputValue] = React.useState(
    ""
  );
  const handleLeaguesSearchInputChange = (e) =>
    setLeaguesSearchInputValue(e.target.value);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [leagueIds, setLeagueIds] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  // Fetching leagues api
  const [leagues, setLeagues] = React.useState([]);
  const fetchLeagues = () => {
    return
    axios
      .get("https://www.footballaroundme.com/api/leagues")
      .then((response) => {
        // console.log(response, 'response')
        setLeagues(response.data.data.leagues);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => setError(true));
  };

  // Search results of Leagues
  const searchedData =
    leagues &&
    leagues.filter((item) =>
      item.name.toLowerCase().includes(leaguesSearchInputValue.toLowerCase())
    );

  // Fetching fixtures api
  const [fixtures, setFixtures] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const [searchedTeam, setSearchedTeam] = React.useState("");
  const handleSearchTeamChange = (e) => {
    setSearchedTeam(e.target.value);
  };

  const fetchFixtures = (pageNumber, team, ids) => {
    return false
    if (page && team === "" && ids.length === 0) {
      axios
        .get(`https://www.footballaroundme.com/api/fixtures/${page}`)
        .then((res) => {
          setFixtures(res.data.data.fixtures);
          setTotal(res.data.data.length);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => setError(true));
    } else if (page && team && ids.length === 0) {
      const teamName = team.replace(team[0], team[0].toUpperCase());
      axios
        .get(
          `https://www.footballaroundme.com/api/search-fixtures/${page}?team=${teamName}`
        )
        .then((res) => {
          setFixtures(res.data.data.fixtures);
          setTotal(res.data.data.length);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => setError(true));
    } else if (page && team === "" && ids) {
      axios
        .get(
          `https://www.footballaroundme.com/api/search-fixtures/${page}?leagues=${ids.map(
            (item) => `${item}`
          )}`
        )
        .then((res) => {
          console.log(res);
          setFixtures(res.data.data.fixtures);
          setTotal(res.data.data.length);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => setError(true));
    } else if (page && team && ids) {
      const teamName = team.replace(team[0], team[0].toUpperCase());

      axios
        .get(
          `https://www.footballaroundme.com/api/search-fixtures/${page}?team=${teamName}&leagues=${ids.map(
            (item) => `${item}`
          )}`
        )
        .then((res) => {
          setFixtures(res.data.data.fixtures);
          setTotal(res.data.data.length);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => setError(true));
    }
  };

  // STADIUMS ACTIONS
  const [stadiums, setStadiums] = React.useState([]);
  const [totalStadiums, setTotalStadiums] = React.useState(0);
  const [currentStadium, setCurrentStadium] = React.useState({});
  // TEAM ACTION
  const [currentTeam, setCurrentTeam] = React.useState({});
  //FILTER
  const [filters, setFilter] = React.useState([]);


  const fetchStadiumList = (page, searchedData) => {
    return
    console.log(searchedData, "aaa");
    if (page && searchedData === "") {
      console.log("consition 1");
      axios
        .get(`https://www.footballaroundme.com/api/stadiums/${page}`)
        .then((res) => {
          setStadiums(res.data.data.stadiums);
          setTotalStadiums(res.data.data.length);
        });
    } else if (page && searchedData) {
      console.log("working");
      axios
        .get(
          `https://www.footballaroundme.com/api/search-stadiums/${page}?venue=${searchedData}`
        )
        .then((res) => {
          console.log(res);
          setStadiums(res.data.data.stadiums);
          setTotalStadiums(res.data.data.length);
        });
    }
  };

  // Array of Countries
  const arrayOfCountries = () => {
    const arr = [];
    leagues && leagues.map((item) => arr.push(item.country));
    return [...new Set(arr)];
  };
  const [stadiumReviews, setStadiumReviews] = React.useState([]);
  const fetchStadiumReviews = () => {
    return
    axios
      .get("https://www.footballaroundme.com/api/stadium-reviews")
      .then((res) => setStadiumReviews(res.data.data))
      .then(() => setLoading(false))
      .catch((err) => setError(true));
  };

  const [supporterReviews, setSupporterReviews] = React.useState([]);
  const fetchSupporterReviews = () => {
    axios
      .get("https://www.footballaroundme.com/api/supporter-reviews")
      .then((res) => setSupporterReviews(res.data.data))
      .then(() => setLoading(false))
      .catch((err) => setError(true));
  };

  const [connectModal, setConnectModal] = React.useState(false);

  const toggleModal = () => {
    setConnectModal(!connectModal);
  };
  const [teamName, setTeamName] = React.useState("");
  const [stadiumName, setStadiumName] = React.useState("");

  const [searchStadiumValue, setSearchStadiumValue] = React.useState("");
  const [searchTeamValue, setSearchTeamValue] = React.useState("");
  const [stadiumOptions, setStadiumOptions] = React.useState([]);
  const [stadium, setStadium] = React.useState(null);
  const [team, setTeam] = React.useState(null);
  const [toastErrors, setToastErrors] = React.useState("");
  const [supporter, setSupporter] = React.useState("");
  const [searchSupporterValue, setSearchSupporterValue] = React.useState("");
  const [supporterOptions, setSupporterOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [stadiumLat, setStadiumLat] = React.useState(null);
  const [stadiumLng, setStadiumLng] = React.useState(null);

  return (
    <Context.Provider
      value={{
        setLeaguesSearchInputValue,
        leaguesSearchInputValue,
        handleLeaguesSearchInputChange,
        leagues,
        fetchLeagues,
        searchedData,
        arrayOfCountries,
        selectedItems,
        setSelectedItems,
        fixtures,
        setFixtures,
        fetchFixtures,
        total,
        page,
        setPage,
        setSearchedTeam,
        searchedTeam,
        handleSearchTeamChange,
        leagueIds,
        setLeagueIds,
        loading,
        error,
        setLoading,
        fetchStadiumReviews,
        stadiumReviews,
        fetchSupporterReviews,
        supporterReviews,
        connectModal,
        toggleModal,
        teamName,
        setTeamName,
        stadiumName,
        setStadiumName,
        searchStadiumValue,
        searchTeamValue,
        setSearchStadiumValue,
        setSearchTeamValue,
        stadiumOptions,
        setStadiumOptions,
        stadium,
        setStadium,
        team,
        setTeam,
        toastErrors,
        setToastErrors,
        supporter,
        setSupporter,
        searchSupporterValue,
        setSearchSupporterValue,
        supporterOptions,
        setSupporterOptions,
        isLoading,
        setIsLoading,
        setTotal,
        fetchStadiumList,
        stadiums,
        totalStadiums,
        setTotalStadiums,
        image,
        setImage,
        setStadiums,
        stadiumLat,
        setStadiumLat,
        stadiumLng,
        setStadiumLng,
        currentStadium,
        setCurrentStadium,
        currentTeam,
        setCurrentTeam,
        filters,
        setFilter
      }}
    >
      <Spin style={{ top: "50vh" }} spinning={isLoading}>
        {props.children}
      </Spin>
    </Context.Provider>
  );
};
