import React , {useEffect,useState , useContext} from "react";
import {
  Table,
  Rate,
  Switch,
  Result,
  Tooltip,
  Modal,
  Button,
  AutoComplete,
  notification,
  Form,
  Row,
  Col,
  Spin,
  Input,
  Tag
} from "antd";
import "./StadiumReviews.scss";
import { LoadingOutlined } from "@ant-design/icons";
import StarRatings from "react-star-ratings";

//COMPONENTS
import StadiumModal from './StadiumModal';
import Filter from '../Filter';

// HELPER
import { STATUS } from '../../helper/status';

import { Label } from "reactstrap";
import axios from "axios";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { isEmpty } from "lodash";
import AlertNotification from "../AlertNotification";
import StadiumForm from "../Forms/StadiumForm";
import TeamForm from "../Forms/TeamForm";
import { Context } from "../../Provider";
import API from '../../helper/api';


export const StadiumReviews = ({filters}) => {
  
  const {    
    toggleModal,        
    setCurrentStadium,
    currentStadium,    
  } = useContext(Context);
  console.log(filters)
  
  const [stadiumReview, setStadiumReview] = React.useState([]); 
  const [loader, setLoader] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [total, setTotal] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const fetchStadiumReviews = () => {
    setLoader(true);
    /*
    * IF you need pagination add .get(`stadium-dashboard-reviews?page=${page}&limit=10`) but in this case you need improve filter by backend
    * part to find data according to filter stuff
    */
    API
      .get(`stadium-dashboard-reviews`)
      .then((res) => {
        setStadiumReview(res.data.data.reviews);        
        setTotal(res.data.data.length);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err)
      });
  };
  
  useEffect(() => {
    if(!stadiumReview.length){
      fetchStadiumReviews();
    }   
    
  });  
   

  const renderTag = ( { approved } ) =>{    

    // TODO NEED IMPROVE WHEN IS DATA BASE WILL CHANGE TO >>>> NEW/APPROVED/DECLINED
    const value = approved ? 'APPROVED' : 'DECLINED'
    // STATUS 
  return <Tag color={STATUS[value]}>{value}</Tag>
  }

  const columns = [
    { title: "Username", dataIndex: "reviewer_name", key: "reviewer_name" },
    { title: "Stadium Name", dataIndex: "stadium_name", key: "stadium_name" },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => (
        <StarRatings
          rating={rating}
          starRatedColor="#f7db11"
          numberOfStars={5}
          starDimension="25px"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "connect",
      key: "connect",
      render: (connect, obj) => {        
        return (
          renderTag(obj)
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "approved",
      key: "approved",
      render: (approved, obj) => {
        return (
          <Button
            type='primary'
            onClick={()=>{              
              setCurrentStadium(obj);
              toggleModal()
            }}            
          >
           {'Edit'} 
          </Button>  
        );
      },
      sorter: (a, b) => {
        return b.approved - a.approved;
      },
      showSorterTooltip: false,
    },
  ];
  let data = stadiumReview ? stadiumReview.map((item, i) => {
    return {
              ...item,    
              ...{
                key: item._id,
                rating: 
              (((item.stadium_atmosphere || 0) / 5)) + 
              (((item.stadium_equipment || 0) /5)) + 
              (((item.seat_view || 0) /5)) + 
              (((item.food_expense|| 0) /5)) +
              (((item.food_quality || 0) /5))
            }, 
            };
  }) : [];  
  
  if(filters.length){
    
    if(filters[0]){
      const filter0 = filters[0].toLowerCase();
      data = data.filter((item)=>{
        if(filters[0]){
          if (item && item.reviewer_name && item.reviewer_name.toLowerCase().includes(filter0)) {
            return true;
          }
        }      
      })
    }
    if(filters[1]){
      const filter1 = filters[1].toLowerCase();
      data = data.filter((item)=>{
        if (filters[1]) {
          if (item.stadium_name.toLowerCase().includes(filter1)) return true;
        }     
      })
    }

    if(filters[2]){
      data = data.filter((item)=>{
        if(filters[2]){
          const a = item.food_expense ? item.food_expense : 0;
          const b = item.food_quality ? item.food_quality : 0;
          const c = item.seat_view ? item.seat_view : 0;
          const d = item.stadium_atmosphere ? item.stadium_atmosphere : 0;
          const e = item.stadium_equipment ? item.stadium_equipment : 0;
          
          const middleVal =  Math.round((a+b+c+d+e)/5) ;
          if(+filters[2]===middleVal) return true
        }      
      })
    }

    if(filters[3]!==null&&filters[3]!==undefined){
      data = data.filter((item)=>{
        if(filters[3]!==null&&filters[3]!==undefined){        
          if(filters[3]===item.approved) return true
        }      
      })    
    }   
  }  
  return (
    <div >
{currentStadium._id?<StadiumModal stadiumReview={stadiumReview} setStadiumReview={setStadiumReview}/>: null}     
{loader === false ? (
        <Table
          style={{ marginTop: "3em" }}
          columns={columns}
          dataSource={data}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <Row>
                  <Col span={24}>
                    <h6>About:</h6>
                    <p>{record.about}</p>
                  </Col>
                </Row>
                <div className="rating-categories">
                  <div>
                    <h6>Stadium Atmosphere </h6>
                    <Rate disabled allowHalf value={record.stadium_atmosphere} />
                  </div>
                  <div>
                    <h6>Stadium Equipment</h6>
                    <Rate disabled allowHalf value={record.stadium_equipment} />
                  </div>
                  <div>
                    <h6>Seat View</h6>
                    <Rate disabled allowHalf value={record.seat_view} />
                  </div>
                  <div>
                    <h6>Food Expense</h6>
                    <Rate disabled allowHalf value={record.food_expense} />
                  </div>
                  <div>
                    <h6>Food Quality</h6>
                    <Rate disabled allowHalf value={record.food_quality} />
                  </div>
                </div>
              </div>
            ),
          }}
          pagination={{total:total,showSizeChanger: false,current: page}} 
        onChange={pageData => {
          setPage(pageData.current);
        }}
        ></Table>
      ) : (
        <LoadingOutlined className="loader" />
      ) }     
       <Spin style={{ top: "50vh" }} spinning={spinLoader}></Spin>
    </div>
  );
};

