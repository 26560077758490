import React from "react";
import { Form, Input, Button } from "antd";
import { isEmpty } from "lodash";
const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 16
  }
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16
  }
};

function StadiumForm({ selectedReview, onFinish }) {
  return (
    <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      initialValues={{
        venue:
          (!isEmpty(selectedReview) && selectedReview.connect.stadiumName) || ""
      }}
    >
      <Form.Item
        label="Venue"
        name="venue"
        rules={[
          {
            required: true,
            message: "Please input stadium venue!"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country Code"
        name="countryCode"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Latitude"
        name="location.lat"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Longitude"
        name="location.lng"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Website" name="website">
        <Input />
      </Form.Item>
      <Form.Item label="Capacity" name="capacity">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Phone" name="phone">
        <Input type="tel" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
}
export default StadiumForm;
