import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form,Switch } from "antd"; 
import { LoadingOutlined  } from "@ant-design/icons";
import LocationComponent from "../LocationComponent/LocationComponent";
import API from '../../../helper/api';

const RestaurantModal = (props) => {
    let payload = {
        stadium_id:"",
        name: "Restrant",
        address: "london",
        lat: 59.95,
        lng: 30.33,
        active:true
      }
    const [form] = Form.useForm();
    const [formPayload, setFormPayload] = useState(payload);
    const [restaurantModal, setRestaurantModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const toggleRestaurantModal = (params) => {
      setRestaurantModal(!restaurantModal);
      props.hideRestaurantModal(params);
    };

    useEffect(() => {
        setRestaurantModal(props.showModal);
    }, [props.showModal]);

    useEffect(() => {
        setFormPayload({...formPayload, ...{stadium_id:props.stadiumId}});
        console.log(formPayload);
    }, [props.stadiumId]);

    useEffect(() => {
      if (isLoading) {
        API.put("add-restaurant-stadium", formPayload)
          .then((response) => {
            setLoading(!isLoading);
            toggleRestaurantModal(true);
          })
          .catch((error) => console.log("error", error));
      }
  }, [isLoading]);

    const onChange = (event)=>{
        console.log(event)
        setFormPayload({...formPayload,...{active:event}});
    }
  const getlocation = (event) => {
    setFormPayload({...formPayload, ...event})
  }

  const onFinish = async (values) => {
        console.log(values);
        setFormPayload({...formPayload, ...{name:values.name}});
        setLoading(!isLoading);
  };
    return (
        
            <Modal
            title="Add Restaurant"
            visible={restaurantModal}
            footer={false}
            onCancel={toggleRestaurantModal}
          >
            <Form
            form={form}
              initialValues={payload}
              onFinish={onFinish}
            >
              <>
                <label>Name:</label>
                <Form.Item name="name" rules={[{required: true,message: 'Required Name',}]}>
                  <Input />
                </Form.Item>
                <Form.Item>
                <label>Active:</label>
                        <Switch
                            onChange={onChange}
                            defaultChecked={formPayload.active}
                        ></Switch>
                    </Form.Item>
                <Form.Item>
                <label>Location:</label>
                  {/* <Label className="label-restaurant-detail">Location:</Label> */}
                  <LocationComponent address={formPayload.address} lat={formPayload.lat} lng={formPayload.lng} getlocation={getlocation} />
               </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                    {isLoading ? <LoadingOutlined /> : null}
                  </Button>
                </Form.Item>
              </>
            </Form>
          </Modal>
    )
}

export default RestaurantModal;