import React from "react";
import { AutoComplete, Input } from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import MapComponent from "./MapComponent";
import { Context } from "../../../Provider";

const LocationComponent = (props) => {
  console.log(props)
  const {
    stadiumLat,
    stadiumLng,
    setStadiumLng,
    setStadiumLat,
  } = React.useContext(Context);

  const [address, setAddress] = React.useState(props.address);

  const handleChange = (address) => {
    setAddress(address);
  };
  
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);
    setAddress(value);
    latlng.address = value;
    props.getlocation(latlng);
    // setStadiumLat(latlng.lat);
    // setStadiumLng(latlng.lng);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <Input
            {...getInputProps({ placeholder: "Type your address" })}
          ></Input>
          <div>{loading ? <div>...loading</div> : null}</div>
          {suggestions.map((suggestion) => {
            const style = suggestion.active
              ? {
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                  padding: "4px 11px",
                  border: "1px solid #d9d9d9",
                }
              : {
                  backgroundColor: "#ffffff",
                  cursor: "pointer",
                  padding: "4px 11px",
                  border: "1px solid #d9d9d9",
                };
            return (
              <div {...getSuggestionItemProps(suggestion, { style })}>
                {suggestion.description}
              </div>
            );
          })}
          {props.lat && props.lng && (
            <div style={{ height: "200px", width: "100%", marginTop: "0.5em" }}>
              <MapComponent lat={props.lat} lng={props.lng}/>
            </div>
          )}
        </>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationComponent;
