import React, { useEffect } from "react";
import { Context } from "./../../Provider";
import { Table, Button, Input, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import API from "../../helper/api";
import "./StadiumList.scss";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "../../helper/toaster";
const { Search } = Input;
const StadiumList = () => {
  const [stadiums, setStadiums] = React.useState([]);
  const [total, setTotal] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [attractionloading, setAttractionLoading] = React.useState(false);
  const [byVenue, setbyVenue] = React.useState("");
  const [byCountry, setByCountry] = React.useState("");
  const [byTeam, setByTeam] = React.useState("");
  const [sortField, setSortField] = React.useState("country");
  const [sortOrder, setSortOrder] = React.useState("asc");
  const [selectedStadium, setSelectedStadium] = React.useState([]);
  const [error, setError] = React.useState(false);

  const fetchStadiumList = () => {
    setLoading(true);
    API.get(`stadiums/${page}`, {
      params: {
        venue: byVenue,
        country: byCountry,
        sortBy: sortField,
        sortOrder: sortOrder,
        teamName: byTeam,
      },
    })
      .then((res) => {
        setStadiums(res.data.data.stadiums);
        setTotal(res.data.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteStadium = (id) => {
    console.log(id);
    API.delete(`remove-stadium/${id}`)
      .then((response) => {
        fetchStadiumList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryName = async (lat, lng) => {
    const apiKey = "AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M"; // Replace with your actual API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;

      if (results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );
        if (countryComponent) {
          return countryComponent.long_name; // Return the country name
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocode data", error);
      return null;
    }
  };

  const sendStadiumData = (data) => {
    API.post(`/attractions-by-location-chat`, { data: data })
      .then((response) => {
        console.log("Response", response);
        setAttractionLoading(false);
        showSuccessToast(response?.data?.data?.message);
      })
      .catch((err) => {
        console.log("Error ==>", err.message);
        showErrorToast(err.message || "Something went wrong");
        setAttractionLoading(false);
      });
  };

  console.log("selectedStadium", selectedStadium);

  const handleAttraction = async () => {
    if (!attractionloading) {
      setAttractionLoading(true);

      try {
        let payload = selectedStadium;

        // Use Promise.all to resolve the array of promises
        payload = await Promise.all(
          payload.map(async (stadium) => {
            let countryName = "";
            if (!stadium.country) {
              countryName = await getCountryName(
                stadium.name.lat,
                stadium.name.lng
              );
            }
            return {
              name: stadium.name.stadium,
              coordinates: [stadium.name.lng, stadium.name.lat],
              address: "",
              venueCountry: countryName || stadium.country,
              venueId: stadium.venueId,
            };
          })
        );

        console.log("payload", payload);

        await sendStadiumData(payload);
      } catch (error) {
        console.error("Error sendStadiumData", error);
      } finally {
        setAttractionLoading(false);
      }
    }
  };

  const onVenueSearch = (value) => {
    // if (!value) {
    //   fetchStadiumList();
    // }
    setbyVenue(value);
    setPage(1);
  };
  const onCountrySearch = (value) => {
    // if (!value) {
    //   fetchStadiumList();
    // }
    setByCountry(value);
    setPage(1);
  };

  const onTeamSearch = (value) => {
    // if (!value) {
    //   fetchStadiumList();
    // }
    setByTeam(value);
    setPage(1);
  };

  useEffect(() => {
    fetchStadiumList();
  }, [page, sortField, sortOrder, byVenue, byCountry, byTeam]);

  useEffect(() => {
    if ((byVenue || byCountry || byTeam) && page == 1) {
      console.log("filter");
      fetchStadiumList();
    }
  }, [byVenue, byCountry, byTeam]);
  const handleTableChange = (pagination, filters, sorter) => {
    // Update sorting state based on user action
    setPage(pagination.current);
    if (sorter && sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
    }

    // Always reset pagination to the first page when sorting changes
    // setLoading(true);
  };
  const columns = [
    {
      title: "Stadium",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (obj) => {
        return (
          <Tooltip title="Show Location">
            <a
              href={`http://www.google.com/maps/place/${obj.lat},${obj.lng}`}
              target="_blank">
              {obj.stadium}
            </a>
          </Tooltip>
        );
      },
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      render: (name) => (
        <div style={{ display: "inline-flex" }}>
          <div>
            {" "}
            {name.logo ? (
              <img src={name.logo} alt="img" className="league-logo" />
            ) : (
              ""
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1em",
            }}>
            {" "}
            {name.name ? name.name : ""}
          </div>
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
    },
    {
      dataIndex: "detail",
      render: (id) => {
        return (
          <NavLink to={`stadiumList/stadiumDetail/${id}`}>
            <Button type="primary">See Details</Button>
          </NavLink>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Button
          danger
          onClick={() => {
            deleteStadium(id);
          }}>
          Delete Stadium
        </Button>
      ),
    },
  ];

  const data =
    stadiums &&
    stadiums.map((item) => {
      return {
        key: item._id,
        id: item._id,
        venueId: item.id,
        name: {
          stadium: item.name,
          lat: item?.coordinates?.lat,
          lng: item?.coordinates?.lng,
        },
        team: {
          logo: item.team ? item.team.logo : "",
          name: item.team ? item.team.name : "",
        },
        country: item.country,
        detail: item._id,
      };
    });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedStadium([...selectedRows]);
    },
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Venue"
          className="search-box"
          onSearch={onVenueSearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onVenueSearch("");
            }
          }}
        />
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By team"
          className="search-box"
          onSearch={onTeamSearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onTeamSearch("");
            }
          }}
        />
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Country"
          className="search-box"
          onSearch={onCountrySearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onCountrySearch("");
            }
          }}
        />

        {selectedStadium.length > 0 && (
          <>
            <Button
              type="primary"
              style={{ marginTop: "2em" }}
              onClick={handleAttraction}>
              Create Attraction
              {attractionloading && (
                <LoadingOutlined className="loader-button" />
              )}
            </Button>
          </>
        )}
      </div>
      {loading === false ? (
        <Table
          className="stadiums-table"
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: "20",
            total: total,
            // onChange: (page) => {
            //   setPage(page);
            // },
            current: page,
            showSizeChanger: false,
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          onChange={handleTableChange}
        />
      ) : (
        <LoadingOutlined className="loader" />
      )}
    </div>

    // <div>
    //   {loading === false && error === false ? (
    //     <Table
    //       className="stadiums-table"
    //       columns={columns}
    //       dataSource={data}
    //       pagination={{
    //         pageSize: "20",
    //         total: totalStadiums,
    //         onChange: (page) => {
    //           setLoading(true);
    //           setPage(page);
    //           setFixtures([]);
    //         },
    //         current: page,
    //         showSizeChanger: false,
    //       }}
    //     />
    //   ) : loading === true && error === false ? (
    //     <LoadingOutlined className="loader" />
    //   ) : null}
    //   {error && (
    //     <Result
    //       status="500"
    //       title="500"
    //       subTitle="Sorry, something went wrong."
    //     ></Result>
    //   )}
    // </div>
  );
};

export default StadiumList;
