import React, { useState } from "react";
import { Context } from "./../../Provider";
import {
  Card,
  Input,
  Button,
  Modal,
  Form,
  Row,
  Col,
  AutoComplete,
  message,
} from "antd";
import "./login.scss";
import Header from "../Header/Header.jsx";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
// import axios from "axios";
import StarRatings from "react-star-ratings";
import Logo from "./../../images/app-logo.png";
import API from "../../helper/api";

const Login = (props) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const onFinish = (values) => {
    try {
      if (!values.email) {
        handleErrorMessage("Email is required");
        return;
      }
      if (!values.password) {
        handleErrorMessage("Password is required");
        return;
      }
      if (!loader) {
        const payload = {
          email: values.email,
          password: values.password,
          requestBy: "admin",
        };
        setLoader(true);
        API.post(`/login`, payload)
          .then((response) => {
            localStorage.setItem("token", response?.data?.data?.accessToken);
            setLoader(false);
            window.location.href = "/";
          })
          .catch((err) => {
            handleErrorMessage(err?.response?.data?.error || "Server Error");
            setLoader(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleErrorMessage = (msg) => {
    setError(msg);
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  return (
    <div className="login-main">
      <Header heading={"Login"} />

      <Card>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "1em",
          }}
          className={"login-img-block"}>
          <img src={Logo} alt="logo" style={{ width: "75%" }} />
        </div>

        <Form form={form} onFinish={onFinish}>
          <div>
            <label>Email:</label>
            <Form.Item name="email">
              <Input />
            </Form.Item>
          </div>

          <div>
            <label>Password:</label>
            <Form.Item name="password">
              <Input.Password />
            </Form.Item>
          </div>

          {error && <p className="error-message">Error:{error}</p>}

          <div>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
                {loader ? <LoadingOutlined /> : null}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
