import React, { useState } from "react";

//COMPONENTS
import Filter from '../Filter';
import { AttractionType } from './AttractionType';

const AttractionTypes = () => {
    const [filters, setFilters] = useState([]);

    const handleSetFilter = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <div>
            {/* <div>
                <Filter filters={filters} setFilter={handleSetFilter} />
            </div> */}
            <AttractionType filters={filters} />
        </div>
    );
};

export default AttractionTypes;
