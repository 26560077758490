import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// import { FiSearch } from "react-icons/fi";
// import { RiCloseLine } from "react-icons/ri";
import "./index.scss";

const AutoCompleteSearchBox = ({ country, title, onChange }) => {
  const [localAddress, setLocalAddress] = useState("");
  const handleChange = (address) => {
    setLocalAddress(address);
  };

  useEffect(() => {
    setLocalAddress(title);
  }, [title]);

  const handleSelect = (address) => {
    setLocalAddress(address);
    let data = {};
    if (window.google && window.google.maps) {
      geocodeByAddress(address)
        .then((results) => {
          data["title"] = address.split(",")[0];
          data["google_place_Id"] = results[0].place_id;
          data["address"] = address;
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          data["location"] = latLng;
          onChange(data);
        })
        .catch((error) => console.error("Error", error));
    } else {
      console.error("Google Maps API is not available.");
    }
  };

  const searchOptions = {
    types: [
      "restaurant",
      "tourist_attraction",
      "bar",
      "stadium",
      "point_of_interest",
    ],
    componentRestrictions: { country: country },
  };

  return (
    <PlacesAutocomplete
      value={localAddress}
      searchOptions={searchOptions}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }, i) => (
        <div key={i}>
          <div className="location-search-input-container">
            {/* <FiSearch className="search-icon" size={20} /> */}
            <input
              {...getInputProps({
                placeholder: "Search by location",
                className: "location-search-input",
              })}
            />

            {/* {localAddress && (
              <RiCloseLine
                onClick={() => {
                  setLocalAddress("");
                }}
                className="input-close-icon"
                size={25}
              />
            )} */}
          </div>

          <div className="autocomplete-dropdown-container">
            <div className="absolute-list">
              {loading && <div></div>}

              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoCompleteSearchBox;
