import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Switch, Row, Col, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../../helper/api";
const { Option } = Select;
const EditLeagueModal = (props) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (props.showModal) {
      const { league } = props;
      form.setFieldsValue({
        _id: league._id,
        name: league.league.name,
        country: league.country.name,
        priority: league.priority,
        season_start: league.seasons[0].start,
        season_end: league.seasons[0].end,
        isCurrent: league.isCurrent,
      });
      setModal(true);
    }
  }, [props.showModal]);

  const onChange = (val) => {
    form.setFieldsValue({ isCurrent: val });
  };
  const toggleModal = (params) => {
    if (modal) {
      setModal(false);
      props.hideModal(params);
    }
  };

  const onFinish = (values) => {
    const { league } = props;
    const selectedCountry = props.countries.find(
      (e) => e.name === values.country
    );
    let body = {
      id: league._id,
      priority: values.priority,
      country: {
        name: selectedCountry.name,
        code: selectedCountry.iso_a2,
        flag: selectedCountry.flag,
      },
      isCurrent: values.isCurrent,
      league:{
        ...league.league,name:values.name
      },
      seasons: [
        {
          ...league.seasons[0],
          start: values.season_start,
          end: values.season_end,
        },
      ],
    };
    setLoader(true);
    API.put(`league`, body)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          toggleModal(false);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  return (
    <Modal visible={modal} onCancel={toggleModal} footer={false}>
      <Row>
        <Col span={24} style={{ display: "flex" }}>
          <img
            src={props?.league?.league?.logo}
            alt="img"
            style={{ height: "25px", width: "25px" }}
          ></img>
          <h6>{props?.league?.league?.name}</h6>
        </Col>
      </Row>
      <Form form={form} onFinish={onFinish}>
        <div>
          <label>Name:</label>
          <Form.Item name="name">
            <Input />
          </Form.Item>
        </div>
        <div>
          <label>Country:</label>
          <Form.Item name="country">
            <Select>
              {props.countries.map((option) => (
                <Option key={option._id} value={option.name}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {/* <div>
          <label>Region:</label>
          <Form.Item name="region">
            <Input />
          </Form.Item>
        </div> */}
        <div>
          <label>Priority:</label>
          <Form.Item name="priority">
            <Input type="number" />
          </Form.Item>
        </div>
        <div>
          <label>Season Start:</label>
          <Form.Item name="season_start">
            <Input />
          </Form.Item>
        </div>
        <div>
          <label>Season End:</label>
          <Form.Item name="season_end">
            <Input />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="isCurrent" initialValue={props.league.isCurrent} valuePropName="checked">
            <label>Active:</label>

            <Switch style={{ marginLeft: "1em" }} defaultChecked={props.league.isCurrent} onChange={onChange}></Switch>
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "1em" }}
            >
              Submit
              {loader ? <LoadingOutlined /> : null}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export default EditLeagueModal;
