import React from "react";

//COMPONENTS
import Filter from '../Filter';
import { PubsReviews } from './PubsReviews';



class Pub  extends React.Component {
    state = {
        filters: []
    }
    
    setFilter = (filters) =>{
        this.setState({filters})
    } 
    
    render(){
        const { filters } = this.state;
        return (
            <div>
                <div>
                    <Filter filters={filters} setFilter={this.setFilter}/>
                </div>
                <PubsReviews filters={filters}/>
            </div>    
        )
    }  
    
}

export default Pub; 