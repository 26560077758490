//MAIN
import React, { useEffect, useContext } from 'react';
import { Input, Select, InputNumber, Button } from "antd";
import { Context } from "../../Provider";

//STYLES
import './styles.scss';

const bc = 'filter';

const { Option } = Select;



// TODO NEED IMPROVE WHEN IS DATA BASE WILL CHANGE TO >>>> NEW/APPROVED/DECLINED
const Filter = ({filters, setFilter}) => {
    
    

    const commonSetFilter = (val, index)=>{
        filters[index] = val;
        setFilter(filters);                   
    }
    
    return (
    <div className = {bc}>
        <div className = {bc + "__input-block"}>
        <Input.Group className={bc + "__input-block__group"} compact>
            <Input value={filters[0]} onChange={(e)=>{
                const { target: { value } } = e ;                
                commonSetFilter(value, 0);                                                 
                    
            }} style={{ width: '70%' }} placeholder={'filter by username'}/>
            <Input value={filters[1]} onChange={(e)=>{
                const { target: { value } } = e ;                
                commonSetFilter(value, 1);                   
                
            }} style={{ width: '70%' }} placeholder="filter by stadium name" />
            <Input type='number' value={filters[2]} onChange={(e)=>{ 
                const { target: { value } } = e ;                              
                if(value>=1&&value<=5){
                    commonSetFilter(value, 2);                      
                }              
                                   
            }} style={{ width: '50%' }} placeholder={'Rate'}  min="1" max="5"/>        
            <Select value={filters[3]} onChange={(val)=>{                
                    commonSetFilter(val, 3);
                
            }}  style={{ width: '50%' }}  placeholder={'Status'} >
                <Option value={true}>Approved</Option>
                <Option value={false}>Declined</Option>
            </Select>   
        </Input.Group>
        </div>
        <Button onClick={()=>{
            setFilter(['','', null , null])
        }} type={'primary'}>Clear filter</Button>
    </div>
    )
}

export default Filter;