import React from "react";
import {
  Table,
  Rate,  
  Result,  
  Button,  
  Tag
} from "antd";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
import { Context } from "../../Provider";
import "./PubsReviews.scss";
import { LoadingOutlined } from "@ant-design/icons";
import StarRatings from "react-star-ratings";
import { isEmpty } from "lodash";


//COMPONENTS
import { PubsModal } from './PubsModal';

// HELPER
import { STATUS } from '../../helper/status';


export const PubsReviews = ({filters}) => {
  const {
    supporterReviews,
    fetchSupporterReviews,
    loading,
    error,
    toggleModal,    
    toastErrors,    
    selectedItems,
    setSelectedItems,
    searchedTeam,
    setCurrentStadium,
    currentStadium
  } = React.useContext(Context);

    const [supportersData, setSupportersData] = React.useState([]);  
  
  React.useEffect(() => {
    setSelectedItems("All");
    fetchSupporterReviews();

    return function cleanup() {
      setSelectedItems([]);
    };
  }, []);

  React.useEffect(() => {
    let searchedReviews =
      supporterReviews &&
      (searchedTeam
        ? supporterReviews.filter(
            (item) =>
              item.team_name.includes(searchedTeam) ||
              item.stadium_name.includes(searchedTeam)
          )
        : supporterReviews);
    let reviews = [];
    if (!isEmpty(supporterReviews) && selectedItems === "All") {
      for (var i = 0; i < searchedReviews.length; i++) {
        reviews.push({
          stadium_id: supporterReviews[i].stadium_id,
          supporter_id: supporterReviews[i].supporter_id,
          type: supporterReviews[i].type,
          key: supporterReviews[i]._id,
          username: supporterReviews[i].reviewer_name,
          stadium: supporterReviews[i].stadium_name,
          supporter: supporterReviews[i].supporter_name,
          rating:
            ((supporterReviews[i].food_expense
              ? supporterReviews[i].food_expense
              : 0) +
              (supporterReviews[i].food_quality
                ? supporterReviews[i].food_quality
                : 0) +
              (supporterReviews[i].supporter_experience
                ? supporterReviews[i].supporter_experience
                : 0)) /
            3,
          about: supporterReviews[i].about,
          approved: supporterReviews[i].approved,
          foodExpense: supporterReviews[i].food_expense
            ? supporterReviews[i].food_expense
            : 0,
          foodQuality: supporterReviews[i].food_quality
            ? supporterReviews[i].food_quality
            : 0,
          supporterExperience: supporterReviews[i].supporter_experience
            ? supporterReviews[i].supporter_experience
            : 0,
          connect: {
            stadiumName: supporterReviews[i].stadium_name,
            teamName: supporterReviews[i].team_name,
            reviewId: supporterReviews[i]._id,
          },
        });
      }

      setSupportersData(reviews);
    } else if (!isEmpty(supporterReviews) && selectedItems === "Active") {
      let filteredData = searchedReviews.filter(
        (item) => item.approved === true
      );
      for (var i = 0; i < filteredData.length; i++) {
        reviews.push({
          stadium_id: filteredData[i].stadium_id,
          supporter_id: filteredData[i].supporter_id,
          type: filteredData[i].type,
          key: filteredData[i]._id,
          username: filteredData[i].reviewer_name,
          stadium: filteredData[i].stadium_name,
          supporter: filteredData[i].supporter_name,
          rating:
            ((filteredData[i].food_expense ? filteredData[i].food_expense : 0) +
              (filteredData[i].food_quality
                ? filteredData[i].food_quality
                : 0) +
              (filteredData[i].supporter_experience
                ? filteredData[i].supporter_experience
                : 0)) /
            3,
          about: filteredData[i].about,
          approved: filteredData[i].approved,
          foodExpense: filteredData[i].food_expense
            ? filteredData[i].food_expense
            : 0,
          foodQuality: filteredData[i].food_quality
            ? filteredData[i].food_quality
            : 0,
          supporterExperience: filteredData[i].supporter_experience
            ? filteredData[i].supporter_experience
            : 0,
          connect: {
            stadiumName: filteredData[i].stadium_name,
            teamName: filteredData[i].team_name,
            reviewId: filteredData[i]._id,
          },
        });
      }
      setSupportersData(reviews);
    } else if (!isEmpty(supporterReviews) && selectedItems === "Inactive") {
      let filteredData = searchedReviews.filter(
        (item) => item.approved === false
      );
      for (var i = 0; i < filteredData.length; i++) {
        reviews.push({
          stadium_id: filteredData[i].stadium_id,
          supporter_id: filteredData[i].supporter_id,
          type: filteredData[i].type,
          key: filteredData[i]._id,
          username: filteredData[i].reviewer_name,
          stadium: filteredData[i].stadium_name,
          supporter: filteredData[i].supporter_name,
          rating:
            ((filteredData[i].food_expense ? filteredData[i].food_expense : 0) +
              (filteredData[i].food_quality
                ? filteredData[i].food_quality
                : 0) +
              (filteredData[i].supporter_experience
                ? filteredData[i].supporter_experience
                : 0)) /
            3,
          about: filteredData[i].about,
          approved: filteredData[i].approved,
          foodExpense: filteredData[i].food_expense
            ? filteredData[i].food_expense
            : 0,
          foodQuality: filteredData[i].food_quality
            ? filteredData[i].food_quality
            : 0,
          supporterExperience: filteredData[i].supporter_experience
            ? filteredData[i].supporter_experience
            : 0,
          connect: {
            stadiumName: filteredData[i].stadium_name,
            teamName: filteredData[i].team_name,
            reviewId: filteredData[i]._id,
          },
        });
      }
      setSupportersData(reviews);
    }
  }, [supporterReviews, selectedItems, searchedTeam]);

  let data = supportersData;
  console.log(data)
  if(filters.length){
    
    if(filters[0]){
      data = data.filter((item)=>{
        if(filters[0]){
          if(item.username.includes(filters[0])) return true
        }      
      })
    }
    if(filters[1]){
      data = data.filter((item)=>{
        if(filters[1]){
          if(item.connect.stadiumName.includes(filters[1])) return true
        }      
      })
    }

    if(filters[2]){
      data = data.filter((item)=>{
        if(filters[2]){         
          const middleVal =  Math.round(item.rating) ;
          if(+filters[2]===middleVal) return true
        }      
      })
    }

    if(filters[3]!==null&&filters[3]!==undefined){
      data = data.filter((item)=>{
        if(filters[3]!==null&&filters[3]!==undefined){        
          if(filters[3]===item.approved) return true
        }      
      })    
    }
    console.log(data)   
  }  

  const renderTag = ( { approved } ) =>{    

    // TODO NEED IMPROVE WHEN IS DATA BASE WILL CHANGE TO >>>> NEW/APPROVED/DECLINED
    const value = approved ? 'APPROVED' : 'DECLINED'
    // STATUS 
  return <Tag color={STATUS[value]}>{value}</Tag>
  }

  const columns = [
    { title: "Username", dataIndex: "username", key: "username" },
    { title: "Stadium", dataIndex: "stadium", key: "stadium" },
    { title: "Pub", dataIndex: "supporter", key: "supporter" },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => (
        <StarRatings
          rating={rating}
          starRatedColor="#f7db11"
          numberOfStars={5}
          starDimension="25px"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "connect",
      key: "connect",
      render: (connect, obj) => {        
        return (
          renderTag(obj)
        );
      },      
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      render: (approved, obj) => {
        return (
          <Button
            type='primary'
            onClick={()=>{              
              setCurrentStadium(obj);
              toggleModal()
            }}            
          >
           {'Edit'} 
          </Button>  
        );
      },
      sorter: (a, b) => {
        return b.approved - a.approved;
      },
      defaultSortOrder: "ascend",
      showSorterTooltip: false,
    },
  ];
  
  return (
    <div>      
      {currentStadium?.connect?.reviewId?<PubsModal supportersData={supportersData} setSupportersData={setSupportersData}/>: null}  
      {loading === false && error === false ? (
        <Table
          style={{ marginTop: "3em" }}
          columns={columns}
          dataSource={data}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <div>
                  <p>
                    <span className="about">About Supporter:</span>
                    {record.about}
                  </p>
                </div>
                <div className="rating-categories">
                  <div>
                    <h6>Food Expense</h6>
                    <Rate disabled allowHalf value={record.foodExpense} />
                  </div>
                  <div>
                    <h6>Food Quality</h6>
                    <Rate disabled allowHalf value={record.foodQuality} />
                  </div>
                  <div>
                    <h6>Supporter Experience</h6>
                    <Rate
                      disabled
                      allowHalf
                      value={record.supporterExperience}
                    />
                  </div>
                </div>
              </div>
            ),
          }}
        ></Table>
      ) : loading === true && error === false ? (
        <LoadingOutlined className="loader" />
      ) : null}
      {error && (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        ></Result>
      )}      
      {toastErrors && (
        <Toast color="danger">
          <ToastHeader>Error</ToastHeader>
          <ToastBody>{toastErrors}</ToastBody>
        </Toast>
      )}
    </div>
  );
};

