import React from "react";
import GoogleMapReact from "google-map-react";
// import { Icon } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import { Context } from "../../../Provider";

const MapComponent = (props) => {
  
  const Marker = () => (
    <PushpinOutlined
      style={{
        color: "red",
        fontSize: "30px",
      }}
    />
  );
  // const Marker = () => (
  //   <Icon type="environment" style={{ color: "red", fontSize: "36px" }} />
  // );
  return (
    <>
    {props.lat && props.lng && (
    <GoogleMapReact
    bootstrapURLKeys={{ key: "AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M" }}
      // center={[Number(stadiumLat),Number(stadiumLng)]}
      center={{ lat: props.lat , lng: props.lng }}
      zoom={11}
    >
      <Marker lat={props.lat} lng={props.lng} />
    </GoogleMapReact>)}
    </>
  );
};

export default MapComponent;
