import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Modal,
  Form,
  Switch,
  Row,
  Col,
  Select,
  DatePicker,
  AutoComplete,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../../helper/api";
import "./index.scss";
import moment from "moment";
// const { Option } = Select;
const EditFixtureModal = (props) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [descloader, setDescLoader] = useState(false);
  const [searchStadiumValue, setSearchStadiumValue] = useState("");
  const [stadiumOptions, setStadiumOptions] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [editLoading, setEditLoading] = React.useState(false);
  useEffect(() => {
    if (props.showModal) {
      const { fixture } = props;
      form.setFieldsValue({
        _id: fixture._id,
        date: fixture.date ? moment(fixture.date) : null,
        leagueName: fixture.league.name,
        venue: fixture.venue.name,
        matchStatus: fixture.status.long,
        description: fixture.description,
      });
      setModal(true);
    }
  }, [props.showModal]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const onChange = (val) => {
    console.log(val);
    form.setFieldsValue({ isCurrent: val });
  };
  const toggleModal = (params) => {
    if (modal) {
      setModal(false);
      props.hideModal(params);
    }
  };

  const onFinish = (values) => {
    const { fixture } = props;
    let body = {
      ...fixture,
      date: values.date,
      timestamp: values.date.unix(),
      venue: selectedVenue
        ? { ...selectedVenue, isEstimated: true, toBeEstimated: false }
        : fixture.venue,
      isUpdatedFromDashboard: true,
    };
    setLoader(true);
    API.put(`fixtures`, body)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          toggleModal(false);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const fetchStadiums = (searchText) => {
    API.get(`location-search?text=${searchText}`)
      .then((res) => {
        const arr = res.data.data.stadiums;
        setStadiumOptions(arr);
      })
      .catch((err) => console.log(err));
  };
  const debouncedFetchStadiums = debounce(fetchStadiums, 300);

  const handleSelect = (value, option) => {
    const data = stadiumOptions.find((el) => el.name === value);
    setSelectedVenue(data);
  };

  const handleDescription = () => {
    if (!editLoading) {
      setEditLoading(true);
      const { fixture } = props;
      const payload = [fixture._id];
      API.post(`/add-discription-multi`, payload)
        .then((response) => {
          console.log("Response", response);
          setEditLoading(false);
        })
        .catch((err) => {
          console.log("Error", err);
          setEditLoading(false);
        });
    }
  };
  return (
    <Modal visible={modal} onCancel={toggleModal} footer={false}>
      <div
        style={{
          display: "flex",
          height: "60px",
          justifyContent: "space-around",
          alignItems: "center",
        }}>
        <div className="home-team">
          <img
            src={props?.fixture.teams.home.logo}
            alt="img"
            className="flag"
          />
          <span className="team-name">{props?.fixture.teams.home.name}</span>
        </div>
        <div className="vs-div">vs</div>
        <div className="away-team">
          <img
            src={props?.fixture.teams.away.logo}
            alt="img"
            className="flag"
          />
          <span className="team-name">{props?.fixture.teams.away.name}</span>
        </div>
      </div>
      <Form form={form} onFinish={onFinish}>
        <div>
          <label>Date:</label>
          <Form.Item name="date">
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </div>
        <div>
          <label>League Name:</label>
          <Form.Item name="leagueName">
            <Input disabled />
          </Form.Item>
        </div>

        <div>
          <label>Venue:</label>
          <Form.Item name="venue">
            <AutoComplete
              placeholder="Search stadium.."
              value={searchStadiumValue}
              onChange={(val) => setSearchStadiumValue(val)}
              options={stadiumOptions.map((item) => ({
                value: `${item.name}`,
                id: item._id,
              }))}
              onSelect={handleSelect}
              onSearch={(searchText) => {
                if (searchText.length > 2) {
                  debouncedFetchStadiums(searchText);
                }
              }}
            />
          </Form.Item>
        </div>
        <div>
          <label>Match Status:</label>
          <Form.Item name="matchStatus">
            <Input disabled />
          </Form.Item>
        </div>
        {props?.fixture.description && (
          <div>
            <label>Description:</label>
            <Form.Item name="description">
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} disabled />
            </Form.Item>
          </div>
        )}
        <div>
          <Form.Item>
            <div className="button-container">
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "1em" }}>
                Submit
                {loader ? <LoadingOutlined /> : null}
              </Button>

              {!props?.fixture.description && (
                <Button
                  type="primary"
                  style={{ marginTop: "1em" }}
                  onClick={handleDescription}>
                  Add Description
                  {descloader ? <LoadingOutlined /> : null}
                </Button>
              )}
            </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export default EditFixtureModal;
