import { useState } from "react";
import axios from "axios";

const useApiRequest = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const makeRequest = async (url, method = "GET", requestData = null) => {
    setLoading(true);
    setError(null);

    try {
      const config = {
        method,
        url,
        baseURL: process.env.REACT_APP_BASE_URL,
        responseType: "json",
      };

      if (method === "GET") {
        config.params = requestData;
      } else {
        config.data = requestData;
      }

      const response = await axios(config);
      setData(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, makeRequest };
};

export default useApiRequest;
