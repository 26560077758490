import React, { useState, useEffect } from "react";
import { Form, Button, Input, Select, Divider, AutoComplete, message, Upload  } from "antd";
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined,UploadOutlined } from "@ant-design/icons";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import API from '../../../helper/api';

const { TextArea } = Input;
const { Option } = Select;

const CreateRivalries = (props) => {
    const [form] = Form.useForm();
    // const [editorState, setEditorState] = React.useState(
    //     () => EditorState.createEmpty(),
    //   );

    const [value, setValue] = useState('');
    const [teamA, setTeamA] = React.useState([]);
    const [teamB, setTeamB] = React.useState([]);
    const [type, setType] = React.useState(['Big Match', 'Rivalry']);
    const [typeName, setTypeName] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [fileList, setFileList] = useState([])
    const [formPayload, setFormPayload] = useState(
        {id:'', team_a: "", team_b: "", type: "", teamA: "", teamB: "", description: "", img_url: [], video_url: [""] });
        const getRivalryById = ()=>{
            API.get( `get-rivalry/${props.match.params.id}`)
            .then((res) => {
              console.log(res)
              let data = res.data.data.rivalries;
              let image = data.img_url.map((img,index)=>{
               return {
                    uid: index,
                    name: '',
                    status: 'done',
                    url: img,
                  }
              })
              setFileList(image)
              setFormPayload({
                team_a:data.team_a.team.id,
                team_b:data.team_b.team.id,
                teamA:data.team_a.team.name,
                teamB:data.team_b.team.name,
                  img_url:data.img_url,
                  description:data.description,
                  video_url:data.video_url,
                  type:data.type,
                  id:props.match.params.id
                })
                form.setFieldsValue({
                    teamA: data.team_a.team.name,
                    teamB:data.team_b.team.name,
                  });
                setTeamA([{ item:data.team_a, value: data.team_a.team.name, key: data.team_a._id }]);
                setTeamB([{ item:data.team_b, value: data.team_b.team.name, key: data.team_b._id }])
            })
            .catch((err) => console.log(err));
          }
     
    const createRivalry = () => {
        API.post(`add-rivalry`, formPayload)
            .then((res) => {
                console.log(res);
                if (res.status == 201 && res.data.success) {
                    message.success('Create Rivalry');
                    // form.resetFields();
                    // setFormPayload({ team_a: "",teamA:"",teamB:"", team_b: "", type: "", description: "", img_url: "", video_url: "" })
                }
                setLoader(false);
            }).catch((err) => {
                console.log(err)
                setLoader(false);
            })
    }
    useEffect(() => {
        if (loader) {
            createRivalry();
        }
    }, [loader]);
    useEffect(() => {
        console.log(props.match)
        console.log(props.match.params.id)
        if (props.match.params.id) {
            getRivalryById();
        }
    }, []);
    const onChange = ({ fileList}) => {
            setFileList(fileList);
        // console.log(newFileList)
    };
    const beforeUpload = file => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error("Image must smaller than 2MB!");
        }
        console.log(isJpgOrPng , isLt2M)
        return isJpgOrPng && isLt2M;
      };
    const onEditorChange = (value) => {
        setFormPayload({ ...formPayload, ...{ description: value } });

    }

    const onFinish = (value) => {
        console.log(value);
        console.log(formPayload);
        console.log(fileList);
        if(fileList.length == 0){
            message.error('Image is required')
            return;
        }
        let imageUrl = [];
        fileList.map((res)=>{
            imageUrl.push(res.thumbUrl || res.url)
        })
        value.img_url = imageUrl;
        let payload = { ...formPayload, ...value };
        console.log(payload)
        // return;
        setFormPayload(payload);
        setLoader(true);
    }

    const optionA = teamA.map((res) => {
        return {
            value: res.item.team.name,
            label: (<div style={{ display: "inline-flex" }}>
                <div>
                    {" "}
                    <img src={res.item.team.logo} alt="img" className="league-logo" />
                </div>

                <div
                    style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
                >
                    {" "}
                    {res.item.team.name}
                </div>
            </div>)
        }
    });
    const optionB = teamB.map((res) => {
        return {
            value: res.item.team.name,
            label: (<div style={{ display: "inline-flex" }}>
                <div>
                    {" "}
                    <img src={res.item.team.logo} alt="img" className="league-logo" />
                </div>

                <div
                    style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
                >
                    {" "}
                    {res.item.team.name}
                </div>
            </div>)
        }
    })

    const onTeamASearch = (value) => {
        if (value.length > 2) {
            console.log(value);
            API.get(`league-teams-search?text=${value}`).then((res) => {
                console.log(res);
                const arr = []; 
                res.data.data.teams.forEach((item) =>
                    arr.push({ item, value: item.team.name, key: item._id })
                );
                setTeamA(arr);
            })
                .catch((err) => console.log(err));
        }
    }
    const onTeamBSearch = (value) => {
        if (value.length > 2) {
            console.log(value);
            API.get(`league-teams-search?text=${value}`).then((res) => {
                const arr = [];
                res.data.data.teams.forEach((item) =>
                    arr.push({ item, value: item.team.name, key: item._id })
                );
                setTeamB(arr);
            })
                .catch((err) => console.log(err));
        }
    }
    const onNameChange = event => {
        setTypeName(event.target.value);
    };
    const addItem = () => {
        console.log('addItem');
        setType([...type, typeName]);
    };
    return (
        <div>
            <Form
                form={form}
                initialValues={formPayload}
                onFinish={onFinish}
            > 
                <div>
                    <label>Team A:</label>
                    <Form.Item name="teamA" rules={[{ required: true, message: 'Required Team A' }]}>
                        <AutoComplete
                            onSelect={(value, option) => {
                                console.log(value, option)
                                const result = teamA.find((res) => {
                                    return res.value == value
                                })
                                setFormPayload({ ...formPayload, ...{ team_a: result.key } });
                            }}
                            options={optionA}
                            onSearch={onTeamASearch}
                        />
                    </Form.Item>
                </div>
                <div>
                    <label>Team B:</label>
                    <Form.Item name="teamB" rules={[{ required: true, message: 'Required Team B', }]}>
                        <AutoComplete
                            onSelect={(value, option) => {
                                console.log(value, option)
                                const result = teamB.find((res) => {
                                    return res.value == value
                                });
                                setFormPayload({ ...formPayload, ...{ team_b: result.key } });
                            }}
                            options={optionB}
                            onSearch={onTeamBSearch}
                        />
                    </Form.Item>
                </div>
                <div>
                    <label>Type:</label>
                    <Form.Item name="type" rules={[{ required: true, message: 'Required Type', }]}>
                        <Select
                            placeholder="Select Type"
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input style={{ flex: 'auto' }} value={typeName} onChange={onNameChange} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={addItem}
                                        >
                                            <PlusOutlined /> Add item
              </a>
                                    </div>
                                </div>
                            )}
                        >
                            {type.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div>
                    <label>Description:</label>
                    {/* <Editor editorState={editorState} onChange={setEditorState} /> */}
                    <Form.Item name="description">
                        <ReactQuill style={{ height: '200px' }} theme="snow" value={formPayload.description} onChange={onEditorChange} />
                        {/* <TextArea rows={4} /> */}
                    </Form.Item>
                </div>
                <br />
                <div>
                    <label>Image Url:</label>
                    <Form.Item name="img_url">
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            beforeUpload={() => false}
                        >
                            {fileList.length < 5 && '+ Upload'}
                        </Upload>
                    </Form.Item>
                </div>
                <div>
                    <label>Video Url:</label>
                    <Form.List name="video_url">
                        {(fields, { add, remove }) => {
                            return (
                                <div>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            rules={[{ required: true, message: 'Required Image Url' }]}
                                            key={field.key} >
                                            <Input placeholder="Enter Url" style={{ width: '60%' }} />

                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                style={{ margin: '0 8px' }}
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add();
                                            }}
                                            style={{ width: '60%' }} >
                                            <PlusOutlined /> Add field </Button>
                                    </Form.Item>
                                </div>
                            );
                        }}
                    </Form.List>
                </div>
                <div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginTop: '1em' }}>
                            Submit
                {loader ? <LoadingOutlined /> : null}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
export default CreateRivalries;