import React from "react";
import { Form, Input, Button } from "antd";
import { isEmpty } from "lodash";
const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 16
  }
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16
  }
};

function TeamForm({ selectedReview, stadium, onFinish }) {
  return (
    <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      initialValues={{
        name: !isEmpty(selectedReview) && selectedReview.connect.teamName,
        country: (!isEmpty(stadium) && stadium.country) || "",
        code: (!isEmpty(stadium) && stadium.countryCode) || "",
        venue_name: !isEmpty(selectedReview) && selectedReview.stadium
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input team name!"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="country"
        name="country"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country Code"
        name="code"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Venue Name" name="venue_name">
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
}
export default TeamForm;
