import React, { useEffect, useState } from "react";
import { Table, Tooltip, Input, Select, Rate, Spin, Button } from "antd";
import Moment from "react-moment";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../helper/api";
import "./Fixtures.scss";
import EditFixtureModal from "./EditFixtureModal";
const { Search } = Input;
const { Option } = Select;
const Fixtures = () => {
  const [fixtures, setFixtures] = useState([]);
  const [selectedFixture, setSelectedFixture] = React.useState([]);
  const [fixtureLoading, setFixtureLoading] = React.useState(false);
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [byVenue, setbyVenue] = useState("");
  const [byTeam, setByTeam] = useState("");
  const [byDate, setByDate] = useState("");
  const [byLeague, setByLeague] = useState([]);
  const [league, setLeague] = useState([]);
  const [leagueFetch, setLeagueFetch] = useState(false);
  const [error, setError] = useState(false);
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [modal, setModal] = useState(false);
  const [editFixture, setEditFixture] = useState(null);
  const [pastFixture, setPastFixture] = useState("future");
  const fetchFixtures = () => {
    console.log("byVenue", byVenue);
    let league = "";
    if (byLeague.length !== 0) {
      byLeague.map((item) => {
        if (league) {
          league = `${league},${item.key}`;
        } else {
          league = `${item.key}`;
        }
      });
    }
    setLoading(true);

    API.get(`fixtures/${page}`, {
      params: {
        page: page,
        team: byTeam,
        venue: byVenue,
        leagues: league,
        date: byDate,
        pastFixture: pastFixture,
        sortBy: sortField,
        sortOrder: sortOrder,
      },
    })
      .then((res) => {
        setFixtures(res.data.data.fixtures);
        setTotal(res.data.data.length);
        setLoading(false);
      })
      .catch((err) => setError(true));
  };
  useEffect(() => {
    fetchFixtures();
  }, [page, sortField, sortOrder]);
  const fetchLeagues = (name) => {
    setLeagueFetch(true);
    API.get(`dashboard/leagues?page=1&limit=20&name=${name}&active=true`)
      .then((response) => {
        setLeague(response.data.data.leagues);
        setLeagueFetch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (
      (byVenue || byTeam || byLeague.length !== 0 || byDate || pastFixture) &&
      page == 1
    ) {
      fetchFixtures();
    }
    // if ((!byVenue || !byTeam || byLeague.length == 0) && page == 1) {
    //   fetchFixtures();
    // }
  }, [byVenue, byTeam, byLeague, byDate, pastFixture]);

  const onVenueSearch = (value) => {
    console.log("Value", value);
    if (!value) {
      setbyVenue(value);
    } else {
      setbyVenue(value);
      setPage(1);
    }
  };

  const onTeamSearch = (value) => {
    if (!value) {
      setByTeam(value);
    } else {
      setByTeam(value);
      setPage(1);
    }
  };
  const onDateSearch = (value) => {
    if (!value) {
      setByDate(value);
      // fetchFixtures();
    } else {
      setByDate(value);
      setPage(1);
    }
  };

  const onLeagueChange = (value) => {
    if (value.length == 0) {
      setByLeague(value);
      fetchFixtures();
    } else {
      setByLeague(value);
      setPage(1);
    }
  };

  const onLeagueSearch = (value) => {
    setTimeout(() => {
      fetchLeagues(value);
    }, 1000);
  };

  const hideModal = (event) => {
    if (!event) {
      setModal(!modal);
      fetchFixtures();
    } else {
      setModal(!modal);
    }
  };

  const onEditFixture = (id) => {
    const fixture = fixtures.find((fix) => fix._id === id);
    setEditFixture(fixture);
    setModal(true);
  };
  const onShowPastFixture = (value) => {
    if (!value) {
      fetchFixtures();
    }
    setPastFixture(value);
    setPage(1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter && sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
  };
  const columns = [
    {
      title: "Teams",
      dataIndex: "teams",
      key: "teams",
      // sorter: true,
      render: (teams) => {
        return (
          <div
            style={{
              display: "flex",
              height: "60px",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <div className="home-team">
              <img src={teams.homeTeam.logo} alt="img" className="flag" />
              <span className="team-name">{teams.homeTeam.name}</span>
            </div>
            <div className="vs-div">vs</div>
            <div className="away-team">
              <img src={teams.AwayTeam.logo} alt="img" className="flag" />
              <span className="team-name">{teams.AwayTeam.name}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Date/Time",
      dataIndex: "date",
      key: "date",
      sorter: true,
      render: (date) => {
        return (
          <div style={{ display: "flex" }}>
            <Moment date={date} format="DD/MM/YYYY HH:mm A" />
          </div>
        );
      },
    },
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      sorter: true,
      render: (data) => {
        return (
          <div>
            <p>
              <span className="fixture-detail-span">
                <img
                  src={data.logo}
                  alt="logo"
                  style={{
                    height: "30px",
                    width: "30px",
                    marginRight: "5px",
                  }}></img>
              </span>
              {data.name}
            </p>
          </div>
        );
      },
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
      sorter: true,
      render: (obj) => (
        <Tooltip title="Show Location">
          <a
            href={`http://www.google.com/maps/place/${obj.venueLocation.lat},${obj.venueLocation.lng}`}
            target="_blank">
            {obj.venueName}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Button
          type="primary"
          onClick={() => {
            onEditFixture(id);
          }}>
          Edit Fixture
        </Button>
      ),
    },
  ];

  const data =
    fixtures &&
    fixtures.map((item) => {
      return {
        key: item.id,
        id: item._id,
        teams: {
          homeTeam: {
            name: item.teams.home.name,
            logo: item.teams.home.logo,
          },
          AwayTeam: {
            name: item.teams.away.name,
            logo: item.teams.away.logo,
          },
        },
        date: item.date,
        venue: {
          venueName: item.venue ? item.venue.name : "-",
          venueLocation: {
            lat: item?.venue?.coordinates?.lat,
            lng: item?.venue?.coordinates?.lng,
          },
        },
        league: item.league,
        country: item?.venue?.country ? item?.venue?.country : "-",
        status: item.status.long,
        capacity: item?.venue?.capacity ? item.venue?.capacity : null,
        rating: item?.location?.rating?.$numberDecimal
          ? item?.location?.rating?.$numberDecimal
          : null,
        website: item?.location?.website ? item?.location?.website : null,
      };
    });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedFixture([...selectedRows]);
    },
  };

  const sendStadiumData = (data) => {
    API.post(`/add-discription-multi`, { ids: data })
      .then((response) => {
        console.log("Response", response);
        setFixtureLoading(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setFixtureLoading(false);
      });
  };

  const handleDescription = async () => {
    if (!fixtureLoading) {
      setFixtureLoading(true);
      try {
        let payload = selectedFixture.map((fixt) => fixt.id);
        await sendStadiumData(payload);
      } catch (error) {
        setFixtureLoading(false);
        console.log("Error fixture", error);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Venue"
          className="search-box"
          onSearch={onVenueSearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onVenueSearch("");
            }
          }}
        />
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Date"
          type="date"
          className="search-box"
          onSearch={onDateSearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onDateSearch("");
            }
          }}
        />
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Team"
          className="search-box"
          onSearch={onTeamSearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onTeamSearch("");
            }
          }}
        />
        <Select
          defaultValue={pastFixture}
          style={{ width: "15%", marginTop: "2em" }}
          onChange={onShowPastFixture}>
          <Option value="all">All Fixture</Option>
          <Option value="past">Past</Option>
          <Option value="future">Current or Upcomming</Option>
        </Select>
        <Select
          mode="multiple"
          labelInValue
          value={byLeague}
          placeholder="Search By League"
          notFoundContent={leagueFetch ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={onLeagueSearch}
          onChange={onLeagueChange}
          style={{ width: "20%", marginTop: "2em" }}>
          {league.map((item) => (
            <Option
              onSelect={onLeagueChange}
              key={item.league_id}
              value={item.league_id}>
              {`${item.league.name} (${item.country.name})`}
            </Option>
          ))}
        </Select>

        {selectedFixture.length > 0 && (
          <>
            <Button
              type="primary"
              style={{ marginTop: "2em" }}
              onClick={handleDescription}>
              Create Description
              {fixtureLoading && <LoadingOutlined className="loader-button" />}
            </Button>
          </>
        )}
      </div>
      {loading === false ? (
        <Table
          columns={columns}
          dataSource={data}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <div>
                  <p>
                    <span className="fixture-detail-span">Country:</span>
                    {record.country}
                  </p>
                </div>
                <div>
                  <p>
                    <span className="fixture-detail-span">Status:</span>
                    {record.status}
                  </p>
                </div>
                <div>
                  <p>
                    <span className="fixture-detail-span">Capacity:</span>
                    {record.capacity !== null
                      ? `${record.capacity} people`
                      : "-"}
                  </p>
                </div>
                <div>
                  <p>
                    <span className="fixture-detail-span">Stadium Rating:</span>
                    {record.rating !== null ? (
                      <Rate disabled allowHalf value={record.rating} />
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
                <div>
                  <p>
                    <span className="fixture-detail-span">
                      Stadium Website:
                    </span>
                    <a href={record.website} target="_blank">
                      {record.website !== null ? record.website : "-"}
                    </a>
                  </p>
                </div>
              </div>
            ),
          }}
          pagination={{
            pageSize: "20",
            total: total,
            // onChange: (page) => {
            //   setLoading(true);
            //   setPage(page);
            //   fetchFixtures();
            // },
            current: page,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      ) : (
        <LoadingOutlined className="loader" />
      )}
      {modal && (
        <EditFixtureModal
          showModal={modal}
          hideModal={hideModal}
          fixture={editFixture}
        />
      )}
    </div>
  );
};

export default Fixtures;
