import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Upload, AutoComplete } from "antd";
import { LoadingOutlined  } from "@ant-design/icons";
import API from '../../../helper/api';

const ConnectToTeamModal = (props) => {
    const [form] = Form.useForm();
    const [team, setTeam] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [teamId, setTeamId] = React.useState('');
    const [modal, setModal] = useState(false);

    // /connect-to-stadium
    const connectTostadium = (teamId) =>{
        let stadium_id = props.stadiumId ? props.stadiumId : '';
        API.put(`connect-to-stadium`,{team_id:teamId, stadium_id: stadium_id})
        .then((res) => {
            console.log(res);
            setModal(!modal);
            setLoader(false)
            props.hideConnectToTeamModal(true);
        }).catch((err)=>{
            console.log(err)
        })
    }
    const toggleModal = () => {
        setModal(!modal);
    };
    useEffect(() => {
        if (props.showModal) {
            toggleModal();
        }
    }, [props.showModal]);
    useEffect(() => {
        if (loader) {
            connectTostadium(teamId);
        }
    }, [loader]);
    const onFinish = (value) => {
        setLoader(true);
    }
    const onTeamSearch = (value) => {
        if (value.length > 2) {
            console.log(value);
            API.get(`league-teams-search?text=${value}`).then((res) => {
                console.log(res);
                const arr = [];
                res.data.data.teams.forEach((item) =>
                    arr.push({ item, value: item.team.name, key: item._id })
                );
                setTeam(arr);
            })
                .catch((err) => console.log(err));
        }
    }
    const option = team.map((res) => {
        return {
            value: res.item.team.name,
            label: (<div style={{ display: "inline-flex" }}>
                <div>
                    {" "}
                    <img src={res.item.team.logo} alt="img" className="league-logo" />
                </div>

                <div
                    style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
                >
                    {" "}
                    {res.item.team.name}
                </div>
            </div>)
        }
    });

    return (
        <Modal
            title="Connect To Team"
            visible={modal}
            onOk={toggleModal}
            onCancel={toggleModal}
            footer={false}
        >
                <Form
                form={form}
                    onFinish={onFinish}
                >
                    <Form.Item name="team" rules={[{ required: true, message: 'Required Team' }]}>
                        <AutoComplete
                            onSelect={(value, option) => {
                                console.log(value, option)
                                const result = team.find((res) => {
                                    return res.value == value
                                });
                                setTeamId(result.key)
                            }}
                            options={option}
                            onSearch={onTeamSearch}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Connect
                {loader ? <LoadingOutlined /> : null}
                        </Button>
                    </Form.Item>
                </Form>

        </Modal>
    )
}
export default ConnectToTeamModal;