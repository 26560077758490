//MAIN
import  React, { useContext } from 'react';
import { Context } from "../../Provider";

//COMPONENTS
import Modal from '../Modal';

import {
  Table,
  Rate,
  Switch,
  Result,
  Tooltip,
  Button,  
  Spin,
  AutoComplete,
  Form,
  Input,
} from "antd";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import StarRatings from "react-star-ratings";
import { Label } from "reactstrap";
import { isEmpty } from "lodash";
import AlertNotification from "../AlertNotification";
import SupporterForm from "../Forms/SupporterForm";
import StadiumForm from "../Forms/StadiumForm";
import API from '../../helper/api';

const { TextArea } = Input;


const StadiumModal = (props) => {
    const { supporterReviews,
        fetchSupporterReviews,
        loading,
        error,
        toggleModal,
        connectModal,
        teamName,
        setTeamName,
        stadiumName,
        setStadiumName,
        searchStadiumValue,
        setSearchStadiumValue,
        stadiumOptions,
        setStadiumOptions,
        stadium,
        setStadium,
        toastErrors,
        setToastErrors,
        supporter,
        setSupporter,
        searchSupporterValue,
        setSearchSupporterValue,
        supporterOptions,
        setSupporterOptions,
        isLoading,
        setIsLoading,
        selectedItems,
        setSelectedItems,
        searchedTeam,
        currentStadium,
        currentTeam,
        setCurrentTeam,
        setSpinLoader,
        setCurrentStadium
    } = useContext(Context)

        const [info, setReviewInfo] = React.useState({about:'', change: false});
        const [showForm, setShowForm] = React.useState({ form: "", show: false });
        const [supportersData, setSupportersData] = React.useState([]);
        const [selectedReview, setSelectedReview] = React.useState({});
        //have copy       

        React.useEffect(()=>{
          currentStadium.stadium_id&&getStadiumDetail(currentStadium.stadium_id);
          currentStadium.team_id&&getTeamDetail(currentStadium.team_id);
          currentStadium.about&&setReviewInfo({ change:false ,about:currentStadium.about})
        },[])

        const onChangeApproval = (val) => {
                   
          API.put("stadium-reviews-approval",{review_id:currentStadium._id,approved:val})
            .then((res) => {
              if(res.data.success){                
                
                const updateStadium = props.stadiumReview.map((review) => {
                  if(review._id === res.data.data._id){
                    review.approved = res.data.data.approved 
                  }
                  return review
                });
                
                props.setStadiumReview(updateStadium);
                
              }
            })
            .catch((err) => {
              console.log(err)
              
            });
        };

        const createForm = (value) => {
            value === "supporter"
              ? setSearchSupporterValue("")
              : setSearchStadiumValue("");
            setShowForm({ form: value, show: true });
          };

          const getTeamDetail = async (id) => {
            axios
              .get(`https://www.footballaroundme.com/api/get-team/${id}`)
              .then((res) => {
                setIsLoading(false);                
                setCurrentTeam(res.data.data.team);
              })
              .catch((err) => setToastErrors(err.message));
          };

          const getSupporterDetail = async (id) => {
            axios
              .get(`https://www.footballaroundme.com/api/get-team/${id}`)
              .then((res) => {
                setIsLoading(false);
                setSupporter(res.data.data.supporters);
              })
              .catch((err) => setToastErrors(err.message));
          };

          const getStadiumDetail = (id) => {
            axios
              .get(`https://www.footballaroundme.com/api/get-stadium/${id}`)
              .then((res) => {
                setIsLoading(false);
                setStadium(res.data.data.stadium);
                console.log(res.data.data.stadium,' res.data.data.stadium')
              })
              .catch((err) => setToastErrors(err.message));
          };        

          const onSupporter = (value) => {
            setIsLoading(true);
            axios
              .post("https://www.footballaroundme.com/api/create-supporter", {
                ...value,
                stadium: stadium._id,
              })
              .then(async (res) => {
                console.log({ res });
        
                setIsLoading(false);
                const { success, data, error } = res.data;
                console.log({ e: res.data });
                if (success) {
                  setShowForm({ form: "", show: false });
                  setSearchSupporterValue(data.name);
                  getSupporterDetail(data._id);
                  return true;
                }
                AlertNotification(error);
              })
              .catch((err) => {
                setIsLoading(false);
                AlertNotification(err.message);
              });
          };
          const onStadium = (value) => {
            setIsLoading(true);
            axios
              .post("https://www.footballaroundme.com/api/create-stadium", {
                ...value,
              })
              .then(async (res) => {
                console.log({ res });
        
                setIsLoading(false);
                const { success, data, error } = res.data;
                console.log({ e: res.data });
                if (success) {
                  setShowForm({ form: "", show: false });
                  setSearchStadiumValue(data.name);
                  getStadiumDetail(data._id);
                  return true;
                }
                AlertNotification(error);
              })
              .catch((err) => {
                setIsLoading(false);
                AlertNotification(err.message);
              });
          };

    return <Modal 
    title={"Connect Stadium "}
      visible={connectModal}
      onCancel={() => {
        toggleModal();
        setSearchStadiumValue("");
        setSearchSupporterValue("");
        setStadium(null);
        setSupporter(null);
        setCurrentTeam({});
        setStadium({});
        setCurrentStadium({});
        setShowForm({ form: "", show: false });
        setSelectedReview({});
      }}
      footer={null}
    >
                 <Spin spinning={isLoading}>
          {!showForm.show ? (
            <div>
              <div>
                <span className="label">Stadium Name : </span>
                <span className="label">{currentStadium.stadium_name?currentStadium.stadium_name : ''}</span>
              </div>
              <div>
                <span className="label">Team Name : </span>
                <span className="label">{currentStadium.team_name? currentStadium.team_name : ''}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label style={{ fontWeight: "bold", marginTop: "7px" }}>
                  Search Stadium
                </Label>
                <AutoComplete
                  placeholder="Search stadium.."
                  value={searchStadiumValue}
                  onChange={(val) => {                    
                    setSearchStadiumValue(val);
                  }}
                  options={searchStadiumValue ? stadiumOptions : []}
                  onSearch={(searchText) => {
                    if (searchText.length > 2) {
                      axios
                        .get(
                          `https://www.footballaroundme.com/api/location-search?text=${searchText}`
                        )
                        .then((res) => {
                          const arr = [];                          
                          res.data.data.stadiums.forEach((item) =>
                            arr.push({ item, value: item.venue })
                          );
                          if (isEmpty(arr)) {
                            arr.push({
                              value: "Create new stadium",
                            });
                          }
                          return setStadiumOptions(arr);
                        })
                        .catch((err) => console.log(err));
                    }
                  }}
                  onSelect={(value, option) =>{
                    console.log(option, 'option')
                    option.value === "Create new stadium"
                      ? createForm("stadium")
                      : getStadiumDetail(option.item._id)
                  }
                    
                  }
                />
                <div>
                  {stadium ? (
                    <div style={{ marginTop: "1em" }}>
                      <h4>Stadium Detail</h4>                      
                      <div>
                        <span className="label">Venue : </span>
                        {stadium.venue || "Not Available"}
                      </div>
                      <div>
                        <span className="label">Country : </span>

                        {stadium.country || "Not Available"}
                      </div>
                      <div>
                        <span className="label">Website : </span>
                        {stadium.website ? (
                          <a
                            href={stadium.website}
                            target="_blank"
                            style={{ marginLeft: "5px" }}
                          >
                            {stadium.website}
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </div>
                      <div>
                        <span className="label">Location : </span>
                        {stadium.location ? (
                          <a
                            href={
                              "https://www.google.com/maps/place/" +
                              stadium.location.lat +
                              "," +
                              stadium.location.lng
                            }
                            target="_blank"
                            style={{ marginLeft: "5px" }}
                          >
                            Open Location
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <Label style={{ fontWeight: "bold", marginTop: "7px" }}>
                  Search team
                </Label>
                <AutoComplete
                  placeholder="Search team"
                  value={searchSupporterValue}
                  onChange={(val) => {
                    setSearchSupporterValue(val);
                  }}
                  options={searchSupporterValue ? supporterOptions : []}
                  onSelect={(value, option) => {
                    option.value === "Create new supporter"
                      ? createForm("supporter")
                      : getTeamDetail(option.item._id);
                  }}
                  onSearch={(searchText) => {
                    if (searchText.length > 2) {
                      axios
                        .get(
                          `https://www.footballaroundme.com/api/league-teams-search?text=${searchText}`
                        )
                        .then((res) => {
                          const arr = [];                          
                          res.data.data.teams.forEach((item) =>
                            arr.push({ item, value: item.name })
                          );
                          if (isEmpty(arr)) {
                            arr.push({
                              value: "Create new supporter",
                            });
                          }
                          return setSupporterOptions(arr);
                        })
                        .catch((err) => setToastErrors(err.message));
                    }
                  }}
                />
                <div>
                  {currentTeam.team_id ? (
                    <div style={{ marginTop: "1em" }}>
                      <h4>Team Detail</h4>

                      <div>
                        <span className="label">Name:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                          {currentTeam.name}
                        </span>
                      </div>
                      <div>
                        <span className="label">Address:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                          {currentTeam.venue_address}
                        </span>
                      </div>
                      <div>
                        <span className="label">Location : </span>
                        {currentTeam.location ? (
                          <a
                            href={
                              "https://www.google.com/maps/place/" +
                              currentTeam.location.lat +
                              "," +
                              currentTeam.location.lng
                            }
                            target="_blank"
                            style={{ marginLeft: "5px" }}
                          >
                            Open Location
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </div>
                      <div>
                        <span className="label">Logo:</span>
                        <img src={currentTeam.logo} style={{ marginLeft: "5px", fontWeight: "500", height: '45px' }}/>                          
                      </div>
                      
                    </div>
                  ) : null}
                </div>
                <div>
                <div>
                <div style={{ marginTop: "1em" }}>
                      <h4>Edit Detail</h4>

                </div>
                <span className="label">About:</span>
                   <TextArea rows={6} onChange={(e)=>{
                     console.log(e.target.value)                     
                     setReviewInfo({change: true, about : e.target.value})
                   }} value={info.about} style={{ marginLeft: "5px", fontWeight: "500" }}/>                        
                </div>                
                </div>
                <div className={'status-block'}>
                  <Button
                    className="status-block__approve-button"
                    type="primary"                    
                    onClick={(e)=>{
                      onChangeApproval(true)
                    }}
                  >Approve
                  </Button>
                  <Button
                    className="status-block__decline-button"
                    type="primary"                    
                    onClick={(e)=>{
                      onChangeApproval(false)
                    }}
                  >Decline
                  </Button>
                </div>  
              </div>
              <Button
                className="connect-button"
                type="primary"
                disabled={!currentStadium._id || !currentTeam._id}
                onClick={() => {
                  setIsLoading(true);
                  var urlencoded = new URLSearchParams();
                  urlencoded.append(
                    "review_id",
                    currentStadium._id ? currentStadium._id.toString() : ""
                  );
                  if(info.change){
                    urlencoded.append("about", info.about.toString());
                  }
                  urlencoded.append("stadium_id", stadium._id.toString());
                  urlencoded.append("team_id", currentTeam._id.toString());

                  var requestOptions = {
                    method: "PUT",
                    body: urlencoded,
                    redirect: "follow",
                  };

                  fetch(
                    "https://www.footballaroundme.com/api/stadium-reviews-linking",
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((result) => {
                      console.log(result);
                      setIsLoading(false);
                      const { success, data, error } = result;
                      if (success) {
                        console.log("connected");
                        const updateStadium = props.stadiumReview.map((review) => {
                          if(review._id === data._id){                            
                            review.stadium_id = data.stadium_id;
                            review.team_id = data.team_id; 
                          }
                          return review
                        });
                        
                        props.setStadiumReview(updateStadium);  
                        
                        toggleModal();
                        setSearchSupporterValue("");
                        setSearchStadiumValue("");
                        setCurrentTeam({});
                        setStadium({});
                        setCurrentStadium({});
                        setStadium(null);
                        setSupporter(null);
                        setSelectedReview({});

                        return true;
                      }
                      AlertNotification(error);
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      setToastErrors(err.message);
                    });
                }}
              >
                Connect Stadium
              </Button>
            </div>
          ) : showForm.form === "supporter" ? (
            <SupporterForm
              onFinish={onSupporter}
              selectedReview={selectedReview}
              stadium={stadium}
            />
          ) : (
            <StadiumForm onFinish={onStadium} selectedReview={selectedReview} />
          )}
        </Spin>
        </Modal>

}







export default StadiumModal;
