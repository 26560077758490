import React, { useEffect, useState } from "react";
import { Table, Button, Select, Spin, Input, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useApiRequest from "../../helper/useApiHook";
import CreateAttraction from "./createAttraction";

const { Option } = Select;
const { Search } = Input;
export const Attractions = ({ filters }) => {
  const [attractions, setAttractions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [attraction, setAttraction] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [byCountries, setByCountries] = useState([]);
  const [byType, setByType] = useState([]);
  const [byTitle, setByTitle] = useState([]);

  const [countriesDataTosearch, setCountriesDataToSearch] = useState([]);
  const { data: attractionData, makeRequest: fetchAttractionData } =
    useApiRequest();
  const { data: attractionTypeData, makeRequest: fetchAttractionTypeData } =
    useApiRequest();
  const { data: countryData, makeRequest: fetchCountryData } = useApiRequest();

  useEffect(() => {
    fetchAttractionData("/attractions", "GET", { page: page, limit: 20 });
    fetchAttractionTypeData("/attractions-types", "GET", { page: page });
    fetchCountryData("/countries", "GET");
  }, []);

  useEffect(() => {
    if (!byTitle || !byCountries || !byType) {
      fetchAttractionData("/attractions", "GET", { page: page, limit: 20 });
    }
  }, [byTitle]);

  // const onCountriesSearch = (value) => {
  //   // Fetch attractions based on selected countries
  //   fetchAttractionData("/attractions", "GET", { page: page, limit: 20, countries: byCountries.map(country => country.value) });
  // };

  const onCountriesChange = (selectedCountries) => {
    setByCountries(selectedCountries);

    fetchAttractionData("/attractions", "GET", {
      page: 1,
      limit: 20,
      countries: selectedCountries.map((country) => country.value),
    });
    setCountriesDataToSearch(countryData);
  };
  const onTypeChange = (selectedTypes) => {
    setByType(selectedTypes);

    fetchAttractionData("/attractions", "GET", {
      page: 1,
      limit: 20,
      attraction_types: selectedTypes.map((type) => type.value),
    });
    setCountriesDataToSearch(countryData);
  };

  useEffect(() => {
    if (countryData) {
      setCountriesDataToSearch(countryData);
    }
  }, [countryData]);
  useEffect(() => {
    if (attractionData) {
      const { total, attractions } = attractionData;
      const data = attractions.map((item) => ({
        title: item.title,
        address: item.address,
        details: item.details,
        type: item.attraction_type.type_name,
        is_approved: item.is_approved,
        country: item.country ? item.country.name : "",
        created_by: item.created_by,
        key: item._id,
        countryCode: item.country ? item.country.short_name : "",
        location: item.location,
      }));
      setTotal(total);
      setAttractions(data);
    }
  }, [attractionData]);

  const editAttraction = (id) => {
    const data = attractions.find((item) => id === item.key);
    setAttraction(data);
    setModal(!modal);
  };

  const columns = [
    { title: "Title", dataIndex: "title", key: "title", sorter: true },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Details", dataIndex: "details", key: "details" },
    { title: "Type", dataIndex: "type", key: "type", sorter: true },
    {
      title: "Approve",
      dataIndex: "is_approved",
      key: "is_approved",
      sorter: false,
      render: (key) => <div>{key ? "Approved" : "Not Approved"}</div>,
    },
    { title: "Country", dataIndex: "country", key: "country", sorter: true },
    {
      title: "CreatedBy",
      dataIndex: "created_by",
      key: "created_by",
      sorter: true,
    },
    {
      title: "Edit",
      dataIndex: "key",
      key: "key",
      render: (key) => (
        <Button type="primary" onClick={() => editAttraction(key)}>
          {"Edit"}
        </Button>
      ),
    },
  ];

  const hideModal = () => {
    setModal(!modal);
    setAttraction(null);
    fetchAttractionData("/attractions", "GET", { page: page, limit: 20 });
  };

  useEffect(() => {
    fetchAttractionData("/attractions", "GET", {
      page: page,
      limit: 20,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
  }, [page, sortField, sortOrder]);
  const onSearch = (value) => {
    // Perform filtering based on the search value
    const filtered = countriesDataTosearch.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setCountriesDataToSearch(filtered);
  };
  let debounceTimer;
  const debounce = (func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
  };

  const onTitleSearchDebounced = (value) => {
    debounce(() => onTitleSearch(value), 500);
  };

  const onTitleSearch = (value) => {
    if (!value) {
      setByTitle(value);
      // fetchFixtures();
      // fetchAttractionData("/attractions", "GET", {
      //   page: page,
      //   limit: 20,
      //   title: value,
      // });
    } else {
      setByTitle(value);
      setPage(1);
      fetchAttractionData("/attractions", "GET", {
        page: page,
        limit: 20,
        title: value,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter && sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? 1 : -1);
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By title"
          className="search-box"
          onSearch={onTitleSearchDebounced} // Use the debounced function here
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onTitleSearchDebounced("");
            }
          }}
        />
        <Select
          mode="multiple"
          labelInValue
          value={byCountries}
          placeholder="Search By Country"
          filterOption={false}
          onSearch={onSearch}
          onChange={onCountriesChange}
          style={{ width: "20%", marginTop: "2em" }}>
          {countriesDataTosearch?.map((item) => (
            <Option key={item._id} value={item._id}>
              {`${item.name}`}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          labelInValue
          value={byType}
          placeholder="Search By Type"
          filterOption={false}
          // onSearch={onSearch}
          onChange={onTypeChange}
          style={{ width: "20%", marginTop: "2em" }}>
          {attractionTypeData?.attractionTypes?.map((item) => (
            <Option key={item._id} value={item._id}>
              {`${item.type_name}`}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          style={{ marginTop: "2em" }}
          onClick={() => setModal(true)}>
          Create Attraction
        </Button>
      </div>

      {attractionData ? (
        <Table
          style={{ marginTop: "3em" }}
          columns={columns}
          dataSource={attractions}
          pagination={{
            pageSize: 20,
            total: total,
            showSizeChanger: false,
            current: page,
          }}
          onChange={handleTableChange}
        />
      ) : (
        <LoadingOutlined className="loader" />
      )}

      {modal && (
        <CreateAttraction
          showModal={modal}
          countries={countryData}
          attraction={attraction}
          attractionTypeData={attractionTypeData.attractionTypes}
          hideModal={hideModal}
        />
      )}
    </div>
  );
};
