import React from "react";
import SideNavbar from "../SideNavbar/SideNavbar";
import SearchBox from "../SearchBox/SearchBox";
import "./Style.scss";
import Header from "../Header/Header";
import { useLocation } from "react-router-dom";
import TableComponent from "../Table/TableComponent";
import SelectComponent from "../Select/SelectComponent";
import { Context } from "./../../Provider";
import AuthModalModal from "../AuthModal";
import { IS_AUTH } from "../../helper/constants";
import { getCookie } from "../AuthModal/helper";

const AllCountries = (props) => {
  const {
    fetchLeagues,
    fetchFixtures,
    leagues,
    page,
    searchedTeam,
    leagueIds,
    fixtures,
    fetchStadiumReviews,
    fetchStadiumList,
  } = React.useContext(Context);

  const location = useLocation();
  const [passwordModal, setPasswordModal] = React.useState(false);

  React.useEffect(() => {
    fetchLeagues();
  }, []);

  React.useEffect(() => {
    if (leagues.length > 0 && fixtures.length === 0) {
      fetchFixtures(page, searchedTeam, leagueIds);
    }
  }, [page, searchedTeam, leagueIds, leagues]);

  React.useEffect(() => {
    fetchStadiumList(page, searchedTeam);
  }, [page, searchedTeam]);

  React.useEffect(() => {
    fetchStadiumReviews();
  }, []);

  // React.useEffect(() => {
  //   handlePasswordModal();
  // }, []);

  // const handlePasswordModal = () => {
  //   const isAuth = getCookie(IS_AUTH);
  //   if (isAuth != "1") {
  //     setPasswordModal(true);
  //   }
  // };

  return (
    <>
      <div style={{ display: "flex" }}>
        <SideNavbar />
        <div className="dashboard-container">
          <Header
            heading={
              location.pathname === "/countriesWithBorders"
                ? "Countries With Borders"
                : location.pathname === "/fixtures"
                ? "Fixtures"
                : location.pathname === "/teams"
                ? "Teams"
                : location.pathname === "/"
                ? "Leagues"
                : location.pathname === "/location"
                ? "Location"
                : location.pathname === "/tickets"
                ? "Tickets"
                : location.pathname === "/attractions"
                ? "Attractions"
                : location.pathname === "/attractionTypes"
                ? "Attraction Types"
                : location.pathname === "/attractionReviews"
                ? "Attraction Reviews"
                : location.pathname === "/stadiumList"
                ? "Stadiums"
                : location.pathname.startsWith("/stadiumList/stadiumDetail")
                ? "Stadium Details"
                : ""
            }
          />
          <div
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
            }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {location.pathname === "/" ||
              location.pathname === "/fixtures" ||
              location.pathname === "/stadiumReviews" ||
              location.pathname === "/pubsReviews" ||
              location.pathname === "/stadiumList" ? (
                <>
                  {/* <SearchBox /> */}
                  {/* {location.pathname === "/stadiumList" ? null : (
                    <SelectComponent />
                  )} */}
                </>
              ) : null}
            </div>
            <TableComponent />
          </div>
        </div>
      </div>

      {/* <AuthModalModal
        showModal={passwordModal}
        hideModal={() => {
          setPasswordModal(false);
        }}
      /> */}
    </>
  );
};

export default AllCountries;
