import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import LocationComponent from "../LocationComponent/LocationComponent";
import API from '../../../helper/api';

const EditStadiumModal = (props) => {
    console.log(props)
    let payload = {
        venue: '',
        totalRating: 5,
        country: '',
        countryCode: '',
        coordinates: { lat: -19.8953399, lng: -44.0487671 },
        _id: '',
        stadium_id:''
    };
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [formPayload, setFormPayload] = useState(payload);

    const toggleModal = (params) => {
        setModal(!modal);
        props.editStadiumModal(params);
        
    };
    const getlocation = (event) => {
        console.log(event)
        setFormPayload({...formPayload, ...{location:{lat:event.lat,lng:event.lng}}})
    }

    useEffect(() => {
        if (props.showModal) {
            setModal(!modal);
            // setFormPayload(props.stadium);
            
            setFormPayload({...formPayload, ...{stadium_id:formPayload._id,coordinates:props.stadium.coordinates,venue:props.stadium.address }})
        }
    }, [props.showModal]);


    useEffect(() => {
        if (isLoading) {
            API.put("edit-stadium", formPayload)
            .then((result) => {
                if(fileList.length !== 0){
                    uploadFile();
                } else{
                    toggleModal(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => console.log("error", error))
        }
    }, [isLoading]);

    const handleUpload = ({ fileList }) => {
        console.log(fileList)
        setFileList(fileList);
    };
    const uploadFile = () => {
        console.log(formPayload)
        if (fileList.length > 0) {
            let formData = new FormData();
            // Object.keys(values).map((key) => {
            //     formData.append(key, values[key]);
            // });
            console.log(fileList)
            // return 
            formData.append("stadium_id", formPayload._id);
            formData.append("venue", formPayload.venue);
            formData.append("image", fileList);
            formData.append("stadium_structure", '');
            var requestOptions = {
                method: "PUT",
                body: formData,
                redirect: "follow",
            };
            fetch(
                "https://www.footballaroundme.com/api/edit-stadium",
                requestOptions
            )
                .then((res) => res.text())
                .then((res) => {
                    console.log(res)
                    setIsLoading(false);
                    toggleModal(true);
                })
                .catch((err) => console.log(err));
        }
    }
    const onFinish = (values) => {
        console.log(values);
        setFormPayload({...formPayload, ...{venue:values.venue,stadium_id:formPayload._id}});
        setIsLoading(true);
    };
    return (
        <Modal
            title="Edit Details"
            visible={modal}
            onOk={toggleModal}
            onCancel={toggleModal}
            footer={false}
        >
            {formPayload ? (
                <Form
                form={form}
                    onFinish={onFinish}
                    initialValues={{
                        venue: formPayload.venue,
                        image: fileList,
                    }}
                >
                    <div>
                        <label className="label-restaurant-detail">Venue:</label>

                        <Form.Item name="venue">
                            <Input />
                        </Form.Item>
                    </div>
                    <div>
                        <label className="label-restaurant-detail">Location:</label>
                        <LocationComponent
                            address={formPayload.venue}
                            lat={formPayload.coordinates.lat}
                            lng={formPayload.coordinates.lng}
                            getlocation={getlocation}
                        />
                    </div>

                    <Form.Item name="image">
                        <div style={{ marginTop: "1em" }}>
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onChange={handleUpload}
                                beforeUpload={() => false}
                            >
                                <Button> <UploadOutlined /> Upload</Button>
                            </Upload>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            submit
                {isLoading ? <LoadingOutlined /> : null}
                        </Button>
                    </Form.Item>
                </Form>
            ) : null}
        </Modal>
    )
}
export default EditStadiumModal;