import React from "react";

//COMPONENTS
import Filter from '../Filter';
import { StadiumReviews } from './StadiumReviews';



class Stadium  extends React.Component {
    state = {
        filters: []
    }
    
    setFilter = (filters) =>{
        this.setState({filters})
    } 
    
    render(){
        const { filters } = this.state;
        return (
            <div>
                <div>
                    <Filter filters={filters} setFilter={this.setFilter}/>
                </div>
                <StadiumReviews filters={filters}/>
            </div>    
        )
    }  
    
}

export default Stadium; 