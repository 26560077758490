import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  Rate,
  Switch,
  Result,
  Tooltip,
  Modal,
  Button,
  AutoComplete,
  notification,
  Form,
  Row,
  Col,
  Spin,
  Input,
  Tag,
} from "antd";
import "./AttractionReviews.scss";
import { LoadingOutlined } from "@ant-design/icons";
import StarRatings from "react-star-ratings";

//COMPONENTS
import Filter from "../Filter";

// HELPER
import { STATUS } from "../../helper/status";

import { Label } from "reactstrap";
import axios from "axios";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { isEmpty } from "lodash";
import AlertNotification from "../AlertNotification";
import StadiumForm from "../Forms/StadiumForm";
import TeamForm from "../Forms/TeamForm";
import { Context } from "../../Provider";
import API from "../../helper/api";
import useApiRequest from "../../helper/useApiHook";
import EditAttractionReview from "./editAttractionReviews";
const { Search } = Input;
export const AttractionReviews = ({ filters }) => {
  const [attractions, setAttractions] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [modal, setModal] = useState(false);
  const [sortField, setSortField] = useState("");
  const [byTitle, setByTitle] = useState("");
  const [byAdress, setByAdress] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [attraction, setAttraction] = useState(null);
  const {
    data: attractionData,
    error: attractionError,
    loading: attractionLoading,
    makeRequest: fetchAttractionData,
  } = useApiRequest();

  const fetchreviewData = () => {
    fetchAttractionData("/attractions-recomendation-dashboard", "GET", {
      page: page,
      limit: 20,
      title: byTitle,
      address: byAdress,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
  };
  useEffect(() => {
    fetchreviewData();
  }, []);

  useEffect(() => {
    if (attractionData) {
      const { total, attractionsRecomendation } = attractionData;
      const data = attractionsRecomendation?.map((item) => ({
        title: item.attraction_id.title,
        address: item.attraction_id.address,
        details: item.attraction_id.details,
        created_by: item.attraction_id.created_by,
        ratings: item.ratings,
        share_your_thoughts: item.share_your_thoughts,
        is_auto_generated: item.is_auto_generated,
        is_approved: item.is_approved,
        key: item._id,
        attraction_id: item.attraction_id._id,
      }));
      setTotal(total);
      setAttractions(data);
    }
  }, [attractionData]);
  const editAttraction = (id) => {
    const data = attractions.find((item) => id === item.key);
    setAttraction(data);
    setModal(!modal);
  };
  const columns = [
    { title: "Title", dataIndex: "title", key: "title", sorter: true },
    { title: "Address", dataIndex: "address", key: "address", sorter: true },
    { title: "Details", dataIndex: "details", key: "details" },
    { title: "CreatedBy", dataIndex: "created_by", key: "created_by" },
    {
      title: "User comments",
      dataIndex: "share_your_thoughts",
      key: "share_your_thoughts",
    },
    {
      title: "Is Auto Generated",
      dataIndex: "is_auto_generated",
      key: "is_auto_generated",
      sorter: true,
      render: (is_auto_generated) => (is_auto_generated ? "Yes" : "No"),
    },
    {
      title: "Is Approved",
      dataIndex: "is_approved",
      key: "is_approved",
      sorter: true,
      render: (is_approved) => (is_approved ? "Yes" : "No"),
    },
    {
      title: "Rating",
      dataIndex: "ratings",
      key: "ratings",
      sorter: true,
      render: (rating) => (
        <StarRatings
          rating={rating}
          starRatedColor="#f7db11"
          numberOfStars={5}
          starDimension="25px"
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "key",
      key: "key",
      render: (key) => {
        return (
          <Button type="primary" onClick={() => editAttraction(key)}>
            {"Edit"}
          </Button>
        );
      },
      // sorter: (a, b) => {
      //   return b.approved - a.approved;
      // },
      // showSorterTooltip: false,
    },
  ];
  useEffect(() => {
    fetchreviewData();
  }, [page, sortField, sortOrder]);
  useEffect(() => {
    if ((byAdress || byTitle) && page === 1) {
      fetchreviewData();
    }
  }, [byAdress, byTitle]);
  const onTitleSearch = (value) => {
    if (!value) {
      fetchreviewData();
    }
    setByTitle(value);
    setPage(1);
  };
  const onAddressSearch = (value) => {
    if (!value) {
      fetchreviewData();
    }
    setByAdress(value);
    setPage(1);
  };
  const hideModal = () => {
    setModal(!modal);
    setAttraction(null);
    fetchreviewData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter && sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? 1 : -1);
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By title"
          className="search-box"
          onSearch={onTitleSearch}
        />
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Address"
          className="search-box"
          onSearch={onAddressSearch}
        />
      </div>
      {attractionLoading === false ? (
        <Table
          style={{ marginTop: "3em" }}
          columns={columns}
          dataSource={attractions}
          pagination={{
            pageSize: "20",
            total: total,
            showSizeChanger: false,
            current: page,
          }}
          onChange={handleTableChange}
        ></Table>
      ) : (
        <LoadingOutlined className="loader" />
      )}
      <Spin style={{ top: "50vh" }} spinning={spinLoader}></Spin>

      {modal && (
        <EditAttractionReview
          showModal={modal}
          attraction={attraction}
          hideModal={hideModal}
        />
      )}
    </div>
  );
};
