import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button, Input, Select, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import API from "../../helper/api";

const { Search } = Input;

const RivalriesList = () => {
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  const [rivalry, setRivalry] = useState([]);
  const [loader, setLoader] = useState([]);
  const [byType, setByType] = useState("");

  const fetchRivalry = () => {
    setLoader(true);
    API.get(`get-rivalry?page=${page}&limit=10&type=${byType}`)
      .then((response) => {
        setRivalry(response.data.data.rivalries);
        setTotal(response.data.data.length);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteRivalry = (id) => {
    console.log(id);
    API.delete(`delete-rivalry/${id}`)
      .then((response) => {
        fetchRivalry();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchRivalry();
  }, [page, byType]);
  useEffect(() => {
    if (byType && page == 1) {
      console.log("filter");
      fetchRivalry();
    }
  }, [byType]);
  const onTypeSearch = (value) => {
    // if (!value) {
    //     fetchRivalry();
    // }
    setByType(value);
    setPage(1);
  };
  const columns = [
    {
      title: "Team A",
      dataIndex: "team_a",
      key: "team_a",
      render: (name) => (
        <div style={{ display: "inline-flex" }}>
          <div>
            {" "}
            <img src={name.logo} alt="img" className="league-logo" />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1em",
            }}>
            {" "}
            {name.name}
          </div>
        </div>
      ),
    },
    {
      title: "Team B",
      dataIndex: "team_b",
      key: "team_b",
      render: (name) => (
        <div style={{ display: "inline-flex" }}>
          <div>
            {" "}
            <img src={name.logo} alt="img" className="league-logo" />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1em",
            }}>
            {" "}
            {name.name}
          </div>
        </div>
      ),
    },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Create At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        return (
          <div style={{ display: "flex" }}>
            <Moment date={date} format="DD/MM/YYYY" />
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        return (
          <NavLink to={`update-rivalries/${id}`}>
            <Button type="primary">Edit Rivalries</Button>
          </NavLink>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Button
          danger
          onClick={() => {
            deleteRivalry(id);
          }}>
          Delete Rivalries
        </Button>
      ),
    },
  ];
  const data = rivalry
    ? rivalry.map((item, i) => {
        return {
          key: item._id,
          team_a: {
            logo: item.team_a?.team?.logo,
            name: item.team_a?.team?.name,
          },
          team_b: {
            logo: item.team_b ? item.team_b?.team.logo : "",
            name: item.team_b ? item.team_b?.team.name : "",
          },
          type: item.type,
          createdAt: item.createdAt,
          id: item._id,
          is_current: item.is_current,
        };
      })
    : [];
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Search
          style={{ width: "200px", height: "31.5px" }}
          placeholder="Search By Type"
          className="search-box"
          onSearch={onTypeSearch}
          onChange={(e) => {
            console.log("Empty Venue", e.target.value);
            if (!e.target.value) {
              console.log("Empty Venue IF", e.target.value);
              onTypeSearch("");
            }
          }}
        />
        <NavLink to={`create-rivalries`}>
          <Button type="primary">Create Rivalries</Button>
        </NavLink>
        {/* <Button
                    type="primary"
                    onClick={() => {console.log('ddd')}}>
                    Create Rivalries
                </Button> */}
      </div>
      {loader === false ? (
        <Table
          columns={columns}
          dataSource={data}
          key="abcd"
          pagination={{ total: total, showSizeChanger: false, current: page }}
          onChange={(pageData) => {
            setPage(pageData.current);
          }}
        />
      ) : (
        <LoadingOutlined className="loader" />
      )}
    </div>
  );
};
export default RivalriesList;
