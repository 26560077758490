import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "draft-js/dist/Draft.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AllCountries from "./components/AllCountries";
import Login from "./components/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">{isAuth ? <Redirect to="/" /> : <Login />}</Route>
        <Route path="/">
          {isAuth ? <AllCountries /> : <Redirect to="/login" />}
        </Route>
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
