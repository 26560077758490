import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  Rate,
  Switch,
  Result,
  Tooltip,
  Modal,
  Button,
  AutoComplete,
  notification,
  Form,
  Row,
  Col,
  Spin,
  Input,
  Tag,
} from "antd";
import "./AttractionType.scss";
import { LoadingOutlined } from "@ant-design/icons";
import StarRatings from "react-star-ratings";

//COMPONENTS
import Filter from "../Filter";

// HELPER
import { STATUS } from "../../helper/status";

import { Label } from "reactstrap";
import axios from "axios";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { isEmpty } from "lodash";
import AlertNotification from "../AlertNotification";
import StadiumForm from "../Forms/StadiumForm";
import TeamForm from "../Forms/TeamForm";
import { Context } from "../../Provider";
import API from "../../helper/api";
import useApiRequest from "../../helper/useApiHook";
import CreateAttractionType from "./createAttractionType";

export const AttractionType = ({ filters }) => {
  const [attractionTypes, setAttractionTpes] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [modal, setModal] = useState(false);
  const [attractionType, setAttractionTpe] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const {
    data: attractionTypeData,
    error: attractionTypeError,
    loading: attractionTypeLoading,
    makeRequest: fetchAttractionTypeData,
  } = useApiRequest();

  useEffect(() => {
    fetchAttractionTypeData("/attractions-types", "GET", {
      page: page,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (attractionTypeData) {
      const { total, attractionTypes } = attractionTypeData;
      const data = attractionTypes.map((item) => ({
        type_name: item.type_name,
        icon: item.icon,
        can_user_review: item.can_user_review,
        key: item._id,
      }));
      setTotal(total);
      setAttractionTpes(data);
    }
  }, [attractionTypeData]);

  const columns = [
    { title: "Title", dataIndex: "type_name", key: "type_name", sorter: true },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (iconUrl) => (
        <img src={iconUrl} alt="Icon" style={{ width: 50, height: 50 }} />
      ),
    },
    {
      title: "Can User Review",
      dataIndex: "can_user_review",
      key: "can_user_review",
      sorter: true,
      render: (can_user_review) => (can_user_review ? "Yes" : "No"),
    },
    {
      title: "Edit",
      dataIndex: "key",
      key: "key",
      render: (key) => {
        return (
          <Button type="primary" onClick={() => onEditModal(key)}>
            {"Edit"}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAttractionTypeData("/attractions-types", "GET", {
      page: page,
      limit: 20,
      sortOrder: sortOrder,
      sortBy: sortField,
    });
  }, [page, sortField, sortOrder]);

  const hideModal = (event) => {
    if (!event) {
      setModal(!modal);
      setAttractionTpe(null);
      fetchAttractionTypeData("/attractions-types", "GET", {
        page: page,
        limit: 20,
      });
    } else {
      setAttractionTpe(null);
      setModal(!modal);
    }
  };

  const onEditModal = (id) => {
    const editAttraction = attractionTypes.find((item) => id === item.key);
    setAttractionTpe(editAttraction);
    setModal(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter && sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
  };
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button type="primary" onClick={() => setModal(true)}>
          Create Attraction Type
        </Button>
      </div>
      {attractionTypeLoading === false ? (
        <Table
          style={{ marginTop: "3em" }}
          columns={columns}
          dataSource={attractionTypes}
          pagination={{
            pageSize: "20",
            total: total,
            showSizeChanger: false,
            current: page,
          }}
          onChange={handleTableChange}
        ></Table>
      ) : (
        <LoadingOutlined className="loader" />
      )}
      <Spin style={{ top: "50vh" }} spinning={spinLoader}></Spin>
      {modal && (
        <CreateAttractionType
          showModal={modal}
          attractionType={attractionType}
          hideModal={hideModal}
        />
      )}
    </div>
  );
};
