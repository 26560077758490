import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Switch, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.scss";
import useApiRequest from "../../../helper/useApiHook";
import StarRatings from "react-star-ratings";

const EditAttractionReview = (props) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [rating, setRating] = useState();

  useEffect(() => {
    if (props.showModal) {
      setModal(true);
      if (props.attraction) {
        form.setFieldsValue({
          title: props.attraction.title,
          address: props.attraction.address,
          details: props.attraction.details,
          share_your_thoughts: props.attraction.share_your_thoughts,
          is_auto_generated: props.attraction.is_auto_generated,
          is_approved: props.attraction.is_approved,
          rating: props.attraction.ratings, // Ensure rating is set
        });
        setRating(props.attraction.ratings);
      } else {
        form.setFieldsValue({
          is_approved: true,
        });
      }
    }
  }, [props.showModal, props.attraction, form]);

  const {
    data: attractionTypeData,
    error: attractionTypeError,
    loading: attractionTypeLoading,
    makeRequest: createAttractionData,
  } = useApiRequest();

  const toggleModal = () => {
    setModal(false);
    props.hideModal(false); // Pass appropriate params if needed
  };

  const onFinish = (values) => {
    let body = {
      id: props.attraction.key,
      attraction_id: props.attraction.attraction_id,
      share_your_thoughts: values.share_your_thoughts,
      is_approved: values.is_approved,
      ratings: rating, // Ensure rating is included
    };
    console.log(body)
    createAttractionData(
      `/attractions-recomendation/${props.attraction.key}`,
      "PUT",
      {
        ...body,
        id: props.attraction.key,
      }
    );
    // }
  };

  useEffect(() => {
    if (attractionTypeError) {
      message.error("Failed to create/update attraction");
    }
    if (attractionTypeData) {
      message.success(
        `${!props.attraction ? "Created" : "Updated"} attraction successfully`
      );
      toggleModal();
    }
  }, [attractionTypeError, attractionTypeData, props]);

  return (
    <Modal visible={modal} onCancel={toggleModal} footer={false}>
      <div className="modal-header">
        <h2>Update Attraction Review</h2>
      </div>
      <Form form={form} onFinish={onFinish}>
        <div>
          <label>Title:</label>
          <Form.Item name="title">
            <Input disabled />
          </Form.Item>
        </div>
        <div>
          <label>Address:</label>
          <Form.Item name="address">
            <Input disabled />
          </Form.Item>
        </div>
        <div>
          <label>Details:</label>
          <Form.Item name="details">
            <Input disabled />
          </Form.Item>
        </div>
        <div>
          <label>User Comments:</label>
          <Form.Item name="share_your_thoughts">
            <Input.TextArea />
          </Form.Item>
        </div>
        <div>
          <label>Ratings</label>
          <Form.Item name="rating">
            <StarRatings
              rating={rating}
              starRatedColor="#f7db11"
              numberOfStars={5}
              starDimension="25px"
              changeRating={(newRating) => {
                setRating(newRating);
              }}
            />
          </Form.Item>
        </div>
        <div>
          <label>Is Approved:</label>
          <Form.Item name="is_approved" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
              {attractionTypeLoading ? <LoadingOutlined /> : null}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EditAttractionReview;
