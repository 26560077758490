// MAIN
import React  from 'react';

//COMPONENTS
import { Modal } from "antd";


const CustomModal = ( {children , ...props} ) =>{
    return <Modal {...props}>
                {children}
            </Modal>
  }

  export default CustomModal;