import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Select, message, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useApiRequest from "../../../helper/useApiHook";
import AutoCompleteSearchBox from "./autoComplete";
import "./index.scss";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreateAttraction = (props) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [attractionDataToCreate, setAttractionDataToCreate] = useState({});

  useEffect(() => {
    if (props.showModal) {
      setModal(true);
      if (props.attraction) {
        form.setFieldsValue({
          title: props.attraction.title,
          address: props.attraction.address,
          details: props.attraction.details,
          attraction_type: props.attraction.type,
          country: props.attraction.country,
          created_by: props.attraction.created_by,
          is_approved: props.attraction.is_approved,
        });
      } else {
        form.setFieldsValue({
          can_user_review: true,
        });
      }
    }
  }, [props.showModal]);

  const {
    data: attractionData,
    error: attractionError,
    loading: attractionLoading,
    makeRequest: createAttractionData,
  } = useApiRequest();

  const toggleModal = (params) => {
    if (modal) {
      setModal(false);
      props.hideModal(params);
    }
  };

  const onFinish = (values) => {
    console.log(values, attractionDataToCreate);
    const { attraction, countries, attractionTypeData } = props;
    let body = {
      title: attractionDataToCreate.title || attraction.title,
      address: values.address,
      details: values.details,
      attraction_type: attractionTypeData.find(
        (at) => at.type_name === values.attraction_type
      )._id,
      country: countries.find((c) => c.name === values.country)._id,
      created_by: values.created_by,
      google_place_Id: attractionDataToCreate.google_place_Id,
      location: attractionDataToCreate.location || attraction.location,
      is_approved: values.is_approved,
    };

    if (!props.attraction) {
      createAttractionData("/attractions", "POST", body);
    } else {
      createAttractionData(`/attractions/${props.attraction.key}`, "PUT", {
        ...body,
        id: props.attraction.key,
      });
    }
  };

  useEffect(() => {
    if (attractionError) {
      message.error("Failed to create/update attraction ");
    }
    if (attractionData) {
      message.success(
        `${!props.attraction ? "Created" : "Updated"} attraction  successfully`
      );
      toggleModal(false);
    }
  }, [attractionError, attractionData]);

  const onChangeAutoComplete = (data) => {
    setAttractionDataToCreate(data);
    form.setFieldsValue({ address: data.address });
  };

  return (
    <Modal visible={modal} onCancel={toggleModal} footer={false}>
      <div
        style={{
          display: "flex",
          height: "60px",
          justifyContent: "space-around",
          alignItems: "center",
        }}>
        <div className="home-team">
          <span className="team-name">
            {!props.attractionType ? "Create" : "Update"} Attraction
          </span>
        </div>
      </div>
      <Form form={form} onFinish={onFinish}>
        <div>
          <label>Title:</label>
          <AutoCompleteSearchBox
            onChange={onChangeAutoComplete}
            title={props.attraction ? props.attraction.title : ""}
            country={props.attraction ? props.attraction.countryCode : ""}
          />
        </div>
        <div>
          <label>Attraction Type:</label>
          <Form.Item name="attraction_type">
            <Select
              showSearch
              dropdownClassName="attraction-type-dropdown"
              optionFilterProp="children">
              {props.attractionTypeData.map((option) => (
                <Option key={option._id} value={option.type_name}>
                  {option.type_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <label>Country:</label>
          <Form.Item name="country">
            <Select
              showSearch
              dropdownClassName="country-dropdown"
              optionFilterProp="children">
              {props.countries.map((option) => (
                <Option key={option._id} value={option.name}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <label>Address:</label>
          <Form.Item name="address">
            <Input />
          </Form.Item>
        </div>
        <div>
          <label>Created By:</label>
          <Form.Item name="created_by">
            <Input />
          </Form.Item>
        </div>
        <div>
          <label>Details:</label>
          <Form.Item name="details">
            <Input />
          </Form.Item>
        </div>
        <div>
          <label>Approved:</label>
          <Form.Item name="is_approved" valuePropName="checked">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              className="custom-switch"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "1em" }}>
              Submit
              {attractionLoading ? <LoadingOutlined /> : null}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAttraction;
