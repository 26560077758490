import React from "react";
import { Form, Input, Button } from "antd";

const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 16
  }
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16
  }
};

function SupporterForm({ selectedReview, stadium, onFinish }) {
  return (
    <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      initialValues={{
        name: selectedReview.supporter,
        type: selectedReview.type || "",
        stadium: (stadium && stadium._id) || "",
        team: selectedReview.connect.teamName,
        about: selectedReview.about,
        type: 'pub'
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input supporter name!"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Type" name="type">
        <Input />
      </Form.Item>
      <Form.Item
        label="Latitude"
        name="location.lat"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Longitude"
        name="location.lng"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Stadium Id"
        name="stadium"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Team"
        name="team"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Address" name="address">
        <Input />
      </Form.Item>
      <Form.Item label="Website" name="website">
        <Input />
      </Form.Item>
      <Form.Item label="About" name="about">
        <Input />
      </Form.Item>
      <Form.Item label="Phone" name="phone">
        <Input type="tel" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
}
export default SupporterForm;
